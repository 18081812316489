import { useState, useEffect } from "react"
import { protocol,endpoint } from "../Config/Endpoint"
import Skeleton from "react-loading-skeleton"
import { useHomeContext } from "../Contexts/HomeContext"
import { searchIcon, verifiedIcon } from "../Helpers/Icons"

export default function Match(props){
    const [match, setMatch] = useState(null)
    const homeContext = useHomeContext()

    function parseMatch(match){



        const newmatch = match
        if(props.userid){
            const ply = newmatch.players?.find?.(ply=>ply.userid===props.userid)
            if(ply){
                ply.highlighted=true
                if(ply.won===true)newmatch.won=true
            }

        }
        newmatch.players.forEach(ply=>{
            if(ply.userid.charAt(0)!=="$"){
                ply.isuser=true
            }

        })

        const mssince = Date.now()- Date.parse(match.enddate)
 
        newmatch.gamemode=match.gamemode.charAt(0).toUpperCase() +  match.gamemode.slice(1)
        if(mssince<120000){
            newmatch.date = `1 minute ago`
        }else if(mssince<3600000){
            newmatch.date = `${Math.floor(mssince/60000)} minutes ago`

        }else if(mssince<7200000){
            newmatch.date = `1 hour ago`
            

        }else if(mssince<86400000){
            newmatch.date = `${Math.floor(mssince/3600000)} hours ago`
        }else if(mssince<172800000){
            newmatch.date = `1 day ago`

        }else if(mssince<2592000000){
            newmatch.date = `${Math.floor(mssince/86400000)} days ago`
        }else{
            newmatch.date = new Date(match.enddate).toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"}) 
        }

        return newmatch

    }



    async function getMatchInfo(matchid){
        try{
            const data = await fetch(protocol+endpoint+"/matches/"+matchid,  {
            "Content-Type":"application/json",   
         method:"GET", credentials:"include"})
            const matchinfo = await data.json()

            
            setMatch(parseMatch(matchinfo))


        }catch(error){





        }






    }
    useEffect(()=>{
        if(props.matchinfo!=null){
            setMatch(parseMatch(props.matchinfo))
        }
    }, [props.matchinfo])

    useEffect(()=>{
        if(props.matchid!=null){
            

            getMatchInfo(props.matchid)



        }
    }, [props.matchid])


    return(<div className="MatchDiv">

        <div className="Data">
            <label className={match?.won===true?"Win":"Loss"}>{match!=null? (match?.won===true?"Win":"Loss"):<Skeleton baseColor="rgb(50,50,50)" height="100%" highlightColor="#363636" enableAnimation={true} />}</label>
            <label>{match!=null? match.gamemode:<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />}</label>
            <label>{match!=null? match.date:<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />}</label>
        </div>


        <div className="ScoreBoard">

            { match!=null?match.players?.map?.((ply,index)=>
            
            
            <div key={index} onClick={()=>{homeContext.dispatch({type:"selectprofile", payload:ply.userid})}} className={"ScoreBoardTag" +(ply.isuser===true?" isuser":"") + (ply.won===true?" won Color"+ply.color1:"")+(ply.highlighted===true?" highlighted":"")}>
                <div className="Colors">
                    <div className={"Color"+ply.color1}></div>
                    <div className={"Color"+ply.color2}></div>


                </div>
                <div className="NameScore">
                <div><label>{ply.name}</label>{ply.isuser===true?<img width={"10px"} height={"10px"} src={verifiedIcon} />:null}</div>
                <label>{ply.score}</label>
                </div>

                

            </div>) :<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />} 

         


         
        </div>

        <div onClick={()=>homeContext?.dispatch?.({type:"selectreplay", payload:props.matchid})} className="RecapBtn">{match!=null? <img width={"20px"} height={"20px"} src={searchIcon}/>:<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />}</div>


        
    </div>)
}