import React, { useState } from "react"


export default function ListedLobby(props){

    const [loading, setLoading]=useState(false)


    async function join(){

        setLoading(1)
        await props.joinLobby(props.game.roomcode)
        setLoading(false)
        

    }

    async function reconnnect(){
        setLoading(2)
    
       await props.sendReconnect()
       
       
       setLoading(false)
       
    }

    async function leave(){
        setLoading(3)
        await props.leaveGame()
        setLoading(false)
        
        
    }


    
 














    
    return(<div className="ListedLobby">

<div className="LobbyMenuContainer listed">
        <div className="LobbyMenu">
            <div className="players">
                <div className={"Color"+props.game.boxes?.[0]?.color}>{props.game.boxes?.[0]?.name}</div>
                <div className={"Color"+props.game.boxes?.[1]?.color}>{props.game.boxes?.[1]?.name}</div>
                <div className={"Color"+props.game.boxes?.[2]?.color}>{props.game.boxes?.[2]?.name}</div>
                <div className={""+(props.game.gamemode === "threes"?" disabled":"")+" Color"+(props.game.boxes?.[3]?.color)}>{props.game.boxes?.[3]?.name}</div>
            </div>

            <label>{"Mode: "+props.game.gamemode.charAt(0).toUpperCase()+props.game.gamemode.slice(1)}</label>
            
            
          { props.prevlobby!==true? <div onClick={join} className={"CreateLobbyBtn join"+(props.disabled?" disabled":"")}>

            <label>Join</label></div>: <div className={"joinleave loading"+loading}><button className={"Surrender"+ (props.disabled?" disabled":"")} onClick={leave}><label>Surrender</label><div className="loaderdiv">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div></button><button onClick={reconnnect} className={"Reconnect"+(props.disabled?" disabled":"")}><label>Reconnect</label> <div className="loaderdiv">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div></button></div>}
       
        </div>
        

        </div>



    </div>)
}