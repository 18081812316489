import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState, useRef } from "react"
import {protocol, endpoint} from "../Config/Endpoint"
import CustomInput from "./CustomInput"
import Match from "./Match"
import { useHomeContext } from "../Contexts/HomeContext"
import DashboardFriendsList from "./DashboardFriendsList"
import { useWebSocketContext } from "../Contexts/WebSocketContext"
import { rightarrowIcon, gearIcon, checkmarkIcon, copyIcon, threesIcon, arrowIcon  } from "../Helpers/Icons"
import accesssettings from "../Config/AccessSettings"

export default function AccountDashboard(props){

    const {isAuthenticated, isLoading,getAccessTokenSilently,user,loginWithRedirect,logout, getAccessTokenWithPopup} = useAuth0()

    const [dashboardInfo, setDashboardInfo] = useState(null)
    const [settingsOpen, setSettingsOpen]= useState(false)
    const [showCopied, setShowCopied]= useState(false)
    const [nameChangeOpen, setNameChangeOpen]= useState(false)
    const [sendNameInputEvent, setSendNameInputEvent]= useState(false)
    const [sendNameErrorEvent, setSendNameErrorEvent]= useState(false)
    const [sendingNameChange, setSendingNameChange]=useState(false)
    const [inputKey, setInputKey]= useState(1)
    const [friendsListOpen, setFriendsListOpen]=useState(false)
    const [selectedMatch, setSelectedMatch]= useState(null)
    const [showVerify, setShowVerify] = useState(null)
    const homeContext = useHomeContext()
    const webSocketContext=useWebSocketContext()

    function copyFriendCode(){
        
        setShowCopied(prev=>{
            if(prev===false){
                navigator.clipboard.writeText(window.location.origin+"/addfriend/"+dashboardInfo?.userID)
                setTimeout(()=>setShowCopied(false), 2000)
                return(true)
            }

        })


    }

    async function attemptNameChange(name){
        if(sendingNameChange)return
        let token = null
        try{
            token = await getAccessTokenSilently()
        }catch(er){
        
            token=null
        }

        setSendingNameChange(true)
        try{
        const response= await fetch(protocol+endpoint+"/user/changename", { headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
        }, method:"POST", credentials:"include", body:JSON.stringify({newname:name})}
            
        )
        setSendingNameChange(false)
        if(response.status===200){
            const json = await response.json()
            setInputKey(prev=>prev+1)
            setDashboardInfo(prev=>{return({...prev, name:json?.name})})
            if(nameChangeOpen){
                setNameChangeOpen(false)
                setSettingsOpen(false)
            }
        }else{
            passSendError()
        }



    }catch(error){
        setSendingNameChange(false)
        passSendError()
    }



    }


    useEffect(()=>{
        const isverified = user?.email_verified
        if(user==null)return
        if(isverified===true){
            setShowVerify(false)
        }else{
            setShowVerify(true)
       
    }

    }, [user])

    function passSendError(){
        setSendNameErrorEvent((prev)=>{
            if(prev===false){
                
                setTimeout(()=>{setSendNameErrorEvent(false)},0)
                return true
            }else{
                return false
            }
        })
    }

    function passSubmitNameChange(){
        setSendNameInputEvent((prev)=>{
            if(prev===false){
                
                setTimeout(()=>{setSendNameInputEvent(false)},0)
                return true
            }else{
                return false
            }
           })
    }

    async function sendEmailVerification(){

        let token = null
        try{
            
            token = await getAccessTokenSilently()

            
        }catch(error){
            if(error=="Error: Please verify your email before logging in.")setShowVerify(true)

            token=null

            

        }



        try{
            const response= await fetch(protocol+endpoint+"/auth/verifyemail", { headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
            }, method:"POST", credentials:"include"})

        }catch(error){
            console.log(error)

        }

            

    }

    async function populatedashboard(){

        let token = null
        try{
          token = await getAccessTokenSilently()

        }catch(error){
            
            
            if(error=="Error: Please verify your email before logging in.")setShowVerify(true)
            if(error=="Error: Consent required")loginWithRedirect()
            token=null

            

            

        }

        
        const response= await fetch(protocol+endpoint+"/user/populatedashboard", { headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
        }, method:"GET", credentials:"include"}
            
        )

        try{



            const data = await response.json()
            data.matches?.forEach?.(match=>{ 
                const mssince = Date.now()- Date.parse(match.date)
 
                match.gamemode=match.gamemode.charAt(0).toUpperCase() +  match.gamemode.slice(1)
                if(mssince<120000){
                    match.message = `1 minute ago`
                }else if(mssince<3600000){
                    match.message = `${Math.floor(mssince/60000)} minutes ago`

                }else if(mssince<7200000){
                    match.message = `1 hour ago`
                    

                }else if(mssince<86400000){
                    match.message = `${Math.floor(mssince/3600000)} hours ago`
                }else if(mssince<172800000){
                    match.message = `1 day ago`

                }else if(mssince<2592000000){
                    match.message = `${Math.floor(mssince/86400000)} days ago`
                }else{
                    match.message = new Date(Date.parse(match.date)).toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"}) 
                }
            })

            if(data.userID?.charAt(0)!=="$"){
                data.matches.reverse()
                
            }
            if(data.matches.length===5){
                data.viewall=true
            }
            data.matches = data.matches.slice(0,4)

            //homeContext.dispatch({type:"setfriends", payload:data?.friends})
            homeContext.dispatch({type:"setLocalUserData", payload:data})

            setDashboardInfo(data)

        }catch(error){

            console.log(error)

        }




            
    }


    useEffect(()=>{
        if(isAuthenticated!=null)populatedashboard()
    }, [isAuthenticated])
 

  
 
   

    return(<div className="AccountDashboardContainer">

    <div className="AccountDashboard">

        {friendsListOpen!=true?(selectedMatch==null?
        <>
        <div className="UserInfoDashboard">

        {(!isLoading&&(!isAuthenticated||showVerify))?<a className={"SignInContainer"+(showVerify===true?" showverify":"")}>
        <div>
        
        {showVerify?<div className="verifyemailbutton"> <div><div onClick={sendEmailVerification}>Resend email</div> <div onClick={logout}>Log out</div></div><label>You must verify your account before signing in.</label></div>
        :<div onClick={()=>props.loginbtnclick()}><label className="needmoreblokio">Need more Blokio?</label><label>Create an account or sign in.</label><img width="30px"height="30px" src={rightarrowIcon}/></div>}

        </div>
        </a>:
        <div className={"UserInfo"+(settingsOpen?" settingsopen":"")+( dashboardInfo==null || showVerify===true?" disabled":"")}>
            <div onClick={()=>{
                setNameChangeOpen(false)
                setSettingsOpen(prev=>!prev)}} className="namediv">
                {
                (dashboardInfo!=null && showVerify!=true)?
                <>
                <label>{dashboardInfo?.name}</label>
                <img width="12px" height="12px"  draggable="false" src={gearIcon}/>
                </>:<Skeleton width={"100%"} baseColor="rgb(50,50,50)" height="100%" highlightColor="#363636" enableAnimation={true}  />
                }
            </div>
            <div className={"SettingsDiv"+(nameChangeOpen===true?" shownamechange":"")}>
                <div className="SettingsOptions">
                    <div onClick={()=>setNameChangeOpen(true)} className="changenamebtn"><label>Change name</label></div>
                    <div className="accountsettingsbtn"><label>Account settings</label></div>
                    <div className="logoutbtn" onClick={logout}><label>Log out</label></div>
                    </div>
                <div className="namechangediv">
                    
                    <div className={"inputandbtn"+(sendingNameChange?" sending":"")}>
                        <CustomInput disabled={sendingNameChange} key={inputKey} placeholder={"Enter name"} event={sendNameInputEvent} errorevent={sendNameErrorEvent} function={attemptNameChange}/>
                        <div onClick={()=>passSubmitNameChange()} className="submitbtn"><img width={"14px"} height={"14px"} src={checkmarkIcon}/>
                        <div className="loaderdiv">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                        </div>
                    </div>
                        <div onClick={()=>{setNameChangeOpen(false)
                        setInputKey(prev=>prev+1)}} className="backbtn"><label>Back</label></div>
                      
                </div>




            </div>



            <div className="UserInfoDiv">
            <div onClick={copyFriendCode} className="copyfriendcode">
                {(dashboardInfo!=null&&showVerify!=true)?
                <>
                <label>Friend code</label>
                {showCopied===false?<img width="12px" height="12px" draggable="false"  src={copyIcon}/>:<img width="12px" height="12px" draggable="false"  src={checkmarkIcon}/>}
                </>:<Skeleton width={"100%"} baseColor="rgb(50,50,50)" height="100%" highlightColor="#363636" enableAnimation={true}  />
                }
            </div>
            <div className="bottominfo">
            <div onClick={()=>setFriendsListOpen(true)} className="friendsdiv">
                <label>{(dashboardInfo!=null&&showVerify!=true)?"Friends":""}&nbsp;</label>
                <div> {(dashboardInfo!=null&&showVerify!=true)?  <> <div> <label>{homeContext?.state?.persistedData?.localuserdata?.friends?.filter?.(friend=>friend.isonline)?.length??0}</label> <div></div> </div> <label>{homeContext?.state?.persistedData?.localuserdata?.friends?.length??0}</label></>:<Skeleton width={"100%"} baseColor="rgb(50,50,50)" height="100%" highlightColor="#363636" enableAnimation={true}  />}</div>
            </div>
            
            <div onClick={()=>homeContext?.dispatch({type:"selectprofile", payload:dashboardInfo.userID})} className="ratingdiv">
                <label>{(dashboardInfo!=null&&showVerify!=true)?"Rating":""}&nbsp;</label>
                <div>{
                (dashboardInfo!=null&&showVerify!=true)? 
                <>
                <div className="standardrating">
                <div className="standardicondiv"><div><div></div><div></div></div><div><div></div><div></div></div></div> 
                    
                <label>{dashboardInfo?.stats?.standard?.rating||1000}</label>    
                </div>


                <div className="modewinrates"> <div>
                    
                <div className="teamsicon"><div><div></div><div></div></div><div><div></div><div></div></div></div>
                    
                    <label>{(Math.ceil((dashboardInfo?.stats?.teams?.won??0)/dashboardInfo?.stats?.teams?.played*100)||"0")+"%"}</label></div> <div>
                    <div className="duelsicon"><div></div><div></div></div>
                     <label>{(Math.ceil((dashboardInfo?.stats?.duel?.won??0)/dashboardInfo?.stats?.duel?.played*100)||"0")+"%"}</label></div>  <div><img src={threesIcon} width="10px" height="10px"/><label>{(Math.ceil((dashboardInfo?.stats?.threes?.won??0)/dashboardInfo?.stats?.threes?.played*100)||"0")+"%"}</label></div> </div>
                </>:<Skeleton width={"100%"} baseColor="rgb(50,50,50)" height="100%" highlightColor="#363636" enableAnimation={true} />

            }</div>

            </div>
            </div>
            </div>




        </div>
        
        
        
        
        }
        </div>

        <div className="MatchHistoryContainer">


            <label>{dashboardInfo!=null?"Match History":""}</label>
            {dashboardInfo?.viewall===true?<button onClick={()=>homeContext?.dispatch({type:"selectprofile", payload:dashboardInfo.userID})}>View all</button>:null}
            <div className="MatchHistoryPreview">
               { dashboardInfo!=null? ( dashboardInfo?.matches?.length>0? dashboardInfo?.matches?.map((match, index)=>{
                                    return(
                                    <div key={index} onClick={()=>setSelectedMatch(match.id)} className="dashboardmatch">
                                    <div className={(match.won?"matchwon":"")}></div>
                                    <label className="matchtype">{match.gamemode}</label>
                                    <label className="matchdate">{match.message}</label>
                
                                </div>
                                )

               }):<label>No matches found.</label>  ):<Skeleton baseColor="rgb(50,50,50)" count={4} height="100%" highlightColor="#363636" enableAnimation={true}  />}


            </div>


        </div>
        </>
        :<div className="DashboardMatchViewer">
        <img onClick={()=>setSelectedMatch(null)} width={"20px"} height={"20px"} src={arrowIcon}/>
        <Match  matchid={selectedMatch} userid={dashboardInfo.userID} /></div>):
        <DashboardFriendsList friendData={{friends:homeContext?.state?.persistedData?.localuserdata?.friends,received:homeContext?.state?.persistedData?.localuserdata?.receivedRequests,sent:homeContext?.state?.persistedData?.localuserdata?.sentRequests}} exitFriendsList={()=>setFriendsListOpen(false)}/>
        }


    </div>



    

    </div>)
}