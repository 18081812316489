import React, {useEffect} from "react"
const defaultmatrix = [[1,1,1,null], [null,null,1,1] ]
export default function SwordsmanPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("N")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}
export{defaultmatrix}