import EmptySpace from "./EmptySpace"
import Block from "./Block"
import { useHomeContext } from "../Contexts/HomeContext";
import React, {useState, useEffect,useRef} from "react"

export default function StaticPiece(props){
    const [defaultMatrix, setDefaultMatrix] = useState([]) 
    
    const [adjustedMatrix, setAdjustedMatrix] = useState([])
    const [pieceName, setPieceName] = useState(null)
    const pieceRef = useRef(null)
    const gameContext = useHomeContext()



    useEffect(()=>{
       
        if(props.coordinates){

  
 
            
            pieceRef.current.style.left = (props.coordinates[0]*32)+"px"
            pieceRef.current.style.bottom = ((18-props.coordinates[1])*32)+12+"px"


        
            }
           

    
  


    }, [props.coordinates])



    useEffect(()=>{
        let workingarray;
        if(defaultMatrix?.length!==0){

            if(props.pieceOrientation?.rotation===0||props.pieceOrientation?.rotation===2){
                workingarray = Array.from(Array(defaultMatrix.length))
                const defaultcopy = defaultMatrix.map((row)=>{return([...row])})
                if (props.pieceOrientation?.rotation===0){
                workingarray=defaultcopy
           
                  

                }else{
                defaultcopy.forEach((row)=>{
                    return row.reverse()
                })
                workingarray=defaultcopy

                workingarray.reverse()
            
                }


            }else{

                workingarray = Array.from(Array(defaultMatrix?.[0].length))
                



                workingarray = workingarray.map((val, ind)=>{
                    
                    const column = []
                    

                    const index = props.pieceOrientation?.rotation===3?((defaultMatrix[0].length-1)-ind):ind

                

                    defaultMatrix.forEach(row=>{
                        
                        column.push(row[index])
                    })
                   
                    if(props.pieceOrientation?.rotation===1){

                    
                        return column.reverse()
                        }else{
                            return column
                        }
                        
                    
                    

                })

            }
            



                if(props.pieceOrientation?.flipped){
                    workingarray.forEach(row=>{
                        row.reverse()
                    })
                }

           
                setAdjustedMatrix(workingarray)

            





        }


        
  

    }, [props.pieceOrientation, defaultMatrix])





    function selectpiece(){
        if(props.setPieceSelected){
            props.setPieceSelected(pieceName,props.pieceOrientation, gameContext.playingfor)
        }

    }


    return(
        <div onClick={()=>selectpiece()} className={"placedpiece"+((pieceName!=null&&props.selectedPiece===pieceName)?" selected":"")+(props.alreadyused === true?" used":"")}>
        <div ref={pieceRef} className="PlayingPiece">

           {React.cloneElement(props.children, {setDefaultMatrix:setDefaultMatrix, setName:setPieceName})}
           
            
           {adjustedMatrix?.map?.((row,ind)=>{
                
                   
                    let index = 0
                    return(
                    <div className="row" key={ind}>

                    {row?.map?.(column=>{
                       
                        if(column==null){
                            
                            return(<EmptySpace key={index++}/>)
                        }else{
                            
                            return(<Block color={gameContext?.state.corners?.[props.pieceCorner]?.color}  key={index++}/>)
                        }
                        
                    })}
                    </div>

                    )
                
            })}
            </div>
            </div>
            )

}