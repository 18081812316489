import { useState, useEffect, useRef } from "react"



export default function ReplayDock(props){
    const [selectedCorner, setSelectedCorner]=useState(null)
    const [selectedIndex, setSelectedIndex] =useState(null)
    const [filteredEvents, setFilteredEvents] = useState(null)
    const [carouselTransition, setCarouselTransition]=useState(null)
    const nextEvent = useRef(null)


    function setSelectedEvent(ev){

        props.setSelectedEvent?.(ev)

    }

    useEffect(()=>{


    }, [props.replay])


    useEffect(()=>{
        setFilteredEvents(props.replay?.eventlog)
    }, [props.replay])


    useEffect(()=>{

       if(props.selectedEvent!=null){
            setSelectedIndex(()=>{
                const index=filteredEvents?.findIndex((event)=>event===props.selectedEvent)
            
                return(index!==-1?index:0)
                })
        }


        if(props.selectedEvent!=null&&filteredEvents?.includes(props.selectedEvent)){
            
            
            return
        }
        setSelectedEvent?.(filteredEvents?.[0])
    
    }, [filteredEvents])


    useEffect(()=>{
        if(selectedCorner!=null){
            setFilteredEvents(props.replay?.eventlog?.filter?.(event=>event.corner===selectedCorner))
        }else{
            setFilteredEvents(props.replay?.eventlog)
        }


    }, [selectedCorner])


    useEffect(()=>{
        setSelectedIndex(()=>{
            const index=filteredEvents?.findIndex((event)=>event===props.selectedEvent)
        
            return(index!==-1?index:0)
            })
            
    },[props.selectedEvent])

    useEffect(()=>{
        let l
        if(carouselTransition!=null){
            l = setTimeout(()=>{

                setCarouselTransition(null)
     
                if(nextEvent?.current!=null)setSelectedEvent?.(nextEvent.current)
               
            },300)
        }
        return(()=>clearTimeout(l))

    },[carouselTransition])



    function goNextEvent(){
        const newEvent=filteredEvents?.[selectedIndex+1]
        if(newEvent!=null)
        setCarouselTransition(prev=>{

            if (prev==null){
                nextEvent.current=newEvent
                return "right"
            }
                return null
        
        
        })

    }
    function goBackEvent(){
        const newEvent=filteredEvents?.[selectedIndex-1]
        if(newEvent!=null){
            setCarouselTransition(prev=>{

                if (prev==null){
                    nextEvent.current=newEvent
                    return "left"
                }
                    return null
            
            
            })
        }
        
    }


    return(<div className="ReplayDock">
        <div className="CornerSelection">
            {props.replay?.corners?.map((corner, index)=>{

                return(<div onClick={()=>{
                    setSelectedCorner(prev=>(prev===index?null:index))}} key={index} className={selectedCorner===index?" selected":""}>
                    <div className={"Color"+corner.color}></div>
                    <label>{corner.name}</label>
                </div>)
            })}
        </div>
        
        <div className="startendbtns">

            <button onClick={()=>setSelectedEvent?.(filteredEvents?.[0])}>Go to start</button>
            <button onClick={()=>setSelectedEvent?.(filteredEvents?.[filteredEvents?.length-1??0])}>Go to end</button>
        
        
        </div>


        <div  className={"EventCarousel "+("transition"+carouselTransition)}>

            <div><div className={"Color"+props.replay?.corners?.[filteredEvents?.[selectedIndex-2]?.corner]?.color}></div><label>{filteredEvents?.[selectedIndex-2]?.name||filteredEvents?.[selectedIndex-2]?.type}</label></div>
            <div onClick={goBackEvent}><div className={"Color"+props.replay?.corners?.[filteredEvents?.[selectedIndex-1]?.corner]?.color}></div><label>{filteredEvents?.[selectedIndex-1]?.name||filteredEvents?.[selectedIndex-1]?.type}</label></div>
            <div className="selectedItem"><div className={"Color"+props.replay?.corners?.[filteredEvents?.[selectedIndex]?.corner]?.color}></div><label>{filteredEvents?.[selectedIndex]?.name||filteredEvents?.[selectedIndex]?.type}</label></div>
            <div onClick={goNextEvent}><div className={"Color"+props.replay?.corners?.[filteredEvents?.[selectedIndex+1]?.corner]?.color}></div><label>{filteredEvents?.[selectedIndex+1]?.name||filteredEvents?.[selectedIndex+1]?.type}</label></div>
            <div><div className={"Color"+props.replay?.corners?.[filteredEvents?.[selectedIndex+2]?.corner]?.color}></div><label>{filteredEvents?.[selectedIndex+2]?.name||filteredEvents?.[selectedIndex+2]?.type}</label></div>


        </div>

    </div>)
}