import React, {useEffect, useState, useRef} from "react"
import { useWebSocketContext } from "../Contexts/WebSocketContext"
import CreateLobby from "./CreateLobby"
import ListedLobby from "./ListedLobby"
import {protocol, endpoint} from "../Config/Endpoint"
import AccountDashboard from "./AccountDashboard"
import { useAuth0 } from "@auth0/auth0-react"
import { refreshIcon } from "../Helpers/Icons"
import accesssettings from "../Config/AccessSettings"

export default function Home(props){
    const [lobbies, setLobbies] = useState([])
    const [prevLobby, setPrevLobby] = useState(null)
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const RequestController = useRef(new AbortController())
    const webSocketContext= useWebSocketContext()
    const pollingLobbies = useRef(null)

    const {getAccessTokenSilently, loginWithPopup}= useAuth0()

    




    useEffect(()=>{
        const pathatjoin = window.location.pathname
        setTimeout(()=>{
            if(pathatjoin?.slice(1,7)==="games/"){
                const roomcode = pathatjoin?.slice(7,12)
                if(roomcode?.length===5){
    
                    sendJoinLobbyRequest(roomcode)
                    
                }
            
    
            }
        },1000)
    }, [])

    async function sendReconnect(){




        setButtonsDisabled(true)
        if(webSocketContext?.state?.socket!=null){
        webSocketContext.dispatch({type:"rejoin"})  
        }

        setTimeout(()=>setButtonsDisabled(false),2000)

        setPrevLobby(null)
        return false
        




    }





    const sendJoinLobbyRequest = async(roomcode)=>{

          

            setButtonsDisabled(true)
            let token = null
            try{
                token = await getAccessTokenSilently()
            }catch{
                token=null

                

            }

        
         const l = await fetch(protocol+endpoint+"/games/joinlobby/"+roomcode, { headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
        }, method:"POST", credentials:"include"})

        if(l.status!==200){
            setButtonsDisabled(false)
            return false
        }
        

    setTimeout(()=>setButtonsDisabled(false),2000)


    }





    async function leaveGame(){
        setButtonsDisabled(true)

        let token

        try{
            token = await getAccessTokenSilently()
        }catch(er){


        }


        try{
        
        const l = await fetch(protocol+endpoint+"/games/leavelobby", { headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
        }, method:"POST",  credentials:"include"})
        setButtonsDisabled(false)
        if(l.status===200){
           
            setPrevLobby(null)
            return true
        }
        setPrevLobby(null)
        return false
        }catch(error){
            setButtonsDisabled(false)
            setPrevLobby(null)
        }
        
        

    }

    function addRemoveLobbies(newlobbies=[],expiredlobbies=[]){
        
        setLobbies(prev=>{

            const updatedlobbies = prev.filter((lobby)=>{
    
        
                return(newlobbies&&!expiredlobbies.includes(lobby.roomcode))
            })
            const newlobbiesactual = newlobbies.filter((lobby)=>{
                return(lobby.roomcode!=prevLobby?.roomcode)
            })
            
           
   
            return(updatedlobbies.concat(newlobbiesactual))

        })



    }

    async function pollLobbies(){

        if(pollingLobbies.current !== true){

       
        let token 
        try{
           token = await getAccessTokenSilently()
        }catch(er){


        }


        try{

        pollingLobbies.current=true



        const res = await fetch(protocol+endpoint+"/polllobbies",  {signal:RequestController.current.signal, headers:{ Authorization:`Bearer ${token}`,
            "Content-Type":"application/json",   
        }, method:"POST",  credentials:"include"})    
        pollingLobbies.current = false
        if(res.status===200){
            const body = await res.json()
            

            addRemoveLobbies(body.newlobbies, body.expiredlobbies)
    
           pollLobbies()


            
        }
    }catch(error){
        //console.log(RequestController.current.signal.reason||error)
        pollingLobbies.current = false
    }

}


    }

    async function getData(){
        try{
        setRefreshLoading(true)


            let token
            try{
                token = await getAccessTokenSilently()
            }catch{
                try{
                 //token = await getAccessTokenWithPopup(accesssettings)
                }catch(err){
                    console.log(err)
                    token = "null"
                }

                

            }
        const res = await fetch(protocol+endpoint+"/games",  { signal:RequestController.current.signal,  headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
        }, method:"GET", credentials:"include"})

        if(res.status===200){

            webSocketContext?.dispatch?.({type:"setready"})

            const body = await res.json()
            setLobbies(body.games||[])
 
            if(body?.prevgame!=null){
            
                setPrevLobby(body.prevgame)
            }
            pollLobbies()
            
            
        }
        setRefreshLoading(false)
    }
    catch(error){
        setRefreshLoading(false)
        console.log(error)
        //console.log(RequestController.current.signal.reason||error)
    }
    }

    useEffect(()=>{


        getData()

        return(()=>{

            RequestController.current.abort("Joined lobby")

        })



    }, [])





 
   
 

    return(
    
    
    
    <div className="HomeDiv">
        <div className="LobbyList">
        {(lobbies.length<1?<div className="nogames"><label className={""+(refreshLoading?" hidelabel":"")}>No public games found.</label><div onClick={()=>getData()} className={"refresh"+(refreshLoading?" loading":"")} ><div className="loaderdiv"><div></div><div></div><div></div>
<div></div></div><img width={"20px"} alt="Refresh lobbies" height={"20px"} src={refreshIcon}/><label>Refresh</label></div></div>
        :lobbies.map((lobby,ind)=>{
            return(<ListedLobby joinLobby={sendJoinLobbyRequest} disabled={buttonsDisabled} key={ind} game={lobby}/>)

        }))}

        </div>
       <div  className="HomeRight">
        {prevLobby==null? <CreateLobby disabled={buttonsDisabled}/>:<ListedLobby disabled={buttonsDisabled} sendReconnect={sendReconnect} leaveGame={leaveGame} prevlobby={true} game={prevLobby}/>}
        
        <AccountDashboard setSelectedProfile={props.setSelectedProfile} loginbtnclick={loginWithPopup}/>
        </div>
    
     

    </div>)
}