import { useEffect, useState, useRef } from "react"
import { useHomeContext } from "../Contexts/HomeContext"
import { useWebSocketContext } from "../Contexts/WebSocketContext"
import { kickIcon, friendsIcon, shuffleIcon, checkmarkIcon} from "../Helpers/Icons"
import FriendInviteModal from "./FriendInviteModal"

export default function Lobby(props){
    const gameState = useHomeContext().state
    const webSocketContext = useWebSocketContext()
    const [choicesOpen, setChoicesOpen] = useState(0)
    const [startTimer, setStartTimer] = useState(null)
    const [showInviteCopied, setShowInviteCopied] = useState(false)
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false)
    const [iterablePlayers, setIterablePlayers]=useState([])
    const [showFriendModal, setShowFriendModal] = useState(false)

    const shuffleCooldown = useRef(null)
    const timerRef = useRef(null)
    const nicknameInput = useRef(null)



    function kickPlayer(player){
            if(gameState.localplayer===gameState.leader)webSocketContext.dispatch({type:"kickPlayer", payload:player.plyid})
           
            
    }


    function changeName(){
        
        if(nicknameInput.current.value.length<13){
            webSocketContext.dispatch({type:"changeName", payload:nicknameInput.current.value})

            
        }
        
        nicknameInput.current.value = ""
  
        setSubmitButtonEnabled(false)



    }


    useEffect(()=>{
        clearTimeout(timerRef.current)
     
    
            if(gameState?.stage!=="loading")return
            if(gameState.timer){
                setStartTimer(Math.floor((gameState.timer-Date.now())/1000))

                const countDownTimer = () =>{

                   return setTimeout(()=>{
                        setStartTimer(prev=>{
                            const next = prev-1
               
                            if (!(next<1))timerRef.current = countDownTimer()
                            return(next)})
    
                    }, 1000)

                }

                timerRef.current = countDownTimer()


            }
     

    },[gameState.timer] )



    useEffect(()=>{
       if(gameState.players)setIterablePlayers(Array.from(gameState.players?.values()))
        
        
    }, [gameState.players])

    useEffect(()=>{
        const onInput = (ev)=>{
        
            if(ev.target.value.length>0){
                setSubmitButtonEnabled(true)
            }else{
                setSubmitButtonEnabled(false)
            }
        }
        if(nicknameInput.current){
            nicknameInput.current.addEventListener("input", onInput)
        }

        return(()=>{
            nicknameInput?.current?.removeEventListener("input", onInput)
        })

    },[nicknameInput])


    
    const copyInvite = () =>{

        if(!showInviteCopied){
            navigator.clipboard.writeText(global.location)
            setShowInviteCopied(true)
            setTimeout(()=>setShowInviteCopied(false), 2000)

        }

    }
    const shuffleTeams = () => {
        
        if(shuffleCooldown.current!==true){
            webSocketContext.dispatch({type:"shuffleTeams"})
            shuffleCooldown.current=true
            setTimeout(()=>{
                shuffleCooldown.current=false
            }, 200)
        }
       

    }

    const selectColor = (color) =>{

        if(choicesOpen===1){
        
            webSocketContext.dispatch({type:"setColor1", payload:color})
        }else if(choicesOpen===2){
            webSocketContext.dispatch({type:"setColor2", payload:color})

        }

        setChoicesOpen(0)

    }

    return(<div className="Lobby actual">

        <div className="LobbyMenuContainer">
        <div className="LobbyMenu">
            <div className="players">
                <div className="playercontainer">
                
                <div className={"plycard Color"+iterablePlayers[0]?.color1}>{iterablePlayers[0]?.name}</div>
                {gameState.gamemode==="teams"?<div className={(gameState?.boxteams?.[0]===0?"Team1":"Team2")}></div>:null}
                {(gameState.isprivate&&gameState.leader===gameState.localplayer&& iterablePlayers[0]?.plyid!==gameState.localplayer&&iterablePlayers[0]!=null)?<div className="kickdiv"><img  onClick={()=>kickPlayer(iterablePlayers[0])}  draggable="false" src={kickIcon}width="16px" height="16px"></img></div>:null}
                </div>
                <div className="playercontainer">
                <div className={"plycard Color"+(gameState?.gamemode==='duel'?iterablePlayers[0]?.color2:iterablePlayers[1]?.color1)}>{(gameState?.gamemode==='duel'?"":iterablePlayers[1]?.name)}</div>
                {gameState.gamemode==="teams"?<div className={(gameState?.boxteams?.[1]===0?"Team1":"Team2")}></div>:null}
                {(gameState.isprivate&&gameState.leader===gameState.localplayer&&gameState.gamemode!=="duel")?(iterablePlayers[1]!=null?<div onClick={()=>kickPlayer(iterablePlayers[1])} className="kickdiv"><img draggable="false" src={kickIcon}width="16px" height="16px"></img></div>:null):null}
                </div>
                <div className="playercontainer">
                <div className={"plycard Color"+(gameState?.gamemode==='duel'?iterablePlayers[1]?.color1:iterablePlayers[2]?.color1)}>{(gameState?.gamemode==='duel'?"":iterablePlayers[2]?.name)}</div>
                {gameState.gamemode==="teams"?<div className={(gameState?.boxteams?.[2]===0?"Team1":"Team2")}></div>:null}
                {(gameState.isprivate&&gameState.leader===gameState.localplayer&&gameState.gamemode!=="duel")?(iterablePlayers[2]!=null?<div onClick={()=>kickPlayer(iterablePlayers[2])} className="kickdiv"><img draggable="false" src={kickIcon}width="16px" height="16px"></img></div>:null):null}
                </div>
                <div className="playercontainer">
                <div className={"plycard"+(gameState?.gamemode === "threes"?" disabled":"")+" Color"+(gameState?.gamemode==="duel"?iterablePlayers[1]?.color2 :iterablePlayers[3]?.color1)}>{(gameState?.gamemode==='duel'?iterablePlayers[1]?.name :iterablePlayers[3]?.name)}</div>
                {gameState.gamemode==="teams"?<div className={(gameState?.boxteams?.[3]===0?"Team1":"Team2")}></div>:null}
                {(gameState.isprivate&&gameState.leader===gameState.localplayer&&gameState.gamemode!=="threes")?((gameState?.gamemode==="duel"?iterablePlayers[1]!=null:iterablePlayers[3]!=null)?<div  onClick={()=>kickPlayer(gameState?.gamemode==="duel"?iterablePlayers[1]:iterablePlayers[3])}  className="kickdiv"><img draggable="false" src={kickIcon}width="16px" height="16px"></img></div>:null):null}
                </div>
            </div>
            <div className="NicknameInput"><label>Nickname</label><div><input spellCheck="false" ref={nicknameInput} maxLength={12} placeholder={iterablePlayers[gameState?.localplayer]?.name||"Guest"}></input> <div onClick={()=>changeName()} className={"submitbtn"+(submitButtonEnabled?" enabled":"")}><img alt="Submit" width={"26px"} height={"26px"} src={checkmarkIcon}/> </div></div></div>
            <div className={"ColorSelect"+(choicesOpen?' selecting':"")}>
                <div className={"SelectedColor1 Color"+gameState?.players?.get(gameState.localplayer)?.color1} onClick={()=>setChoicesOpen(1)}></div>
                { gameState?.gamemode==="duel"?<div className={"SelectedColor2 Color"+gameState?.players?.get(gameState.localplayer)?.color2} onClick={()=>setChoicesOpen(2)}></div>:""}
                

                <div className="choices" >
                <div className={"Color1"+(gameState.colors?.[0]?" selected":"")} onClick={()=>selectColor(1)}></div>
                <div className={"Color2"+(gameState.colors?.[1]?" selected":"")} onClick={()=>selectColor(2)}></div>
                <div className={"Color3" +(gameState.colors?.[2]?" selected":"")} onClick={()=>selectColor(3)}></div>
                <div className={"Color4" +(gameState.colors?.[3]?" selected":"") } onClick={()=>selectColor(4)}></div>
                <div className={"Color5"+(gameState.colors?.[4]?" selected":"")} onClick={()=>selectColor(5)}></div>
                <div className={"Color6"+(gameState.colors?.[5]?" selected":"")} onClick={()=>selectColor(6)}></div>
                <div className={"Color7"+(gameState.colors?.[6]?" selected":"")} onClick={()=>selectColor(7)}></div>
                <div className={"Color8"+(gameState.colors?.[7]?" selected":"")} onClick={()=>selectColor(8)}></div>
                <div className={"Color9"+(gameState.colors?.[8]?" selected":"")} onClick={()=>selectColor(9)}></div>
                </div>


            </div>
            <div className="lobbybtns">
            <div onClick={()=>copyInvite()} className="InviteBtn">Invite</div>
            {gameState?.persistedData?.localuserdata?.friends?.length>0?<div onClick={()=> setShowFriendModal(prev=>!prev)} className="friendsbtn"><img width="16px" height="16px"src={friendsIcon}/></div>:null}
            {(gameState?.gamemode==="teams"&&gameState.leader===gameState.localplayer)?<button className="shufflebtn" onClick={shuffleTeams}><img alt="Shuffle teams" src={shuffleIcon}></img></button>:null}
            </div>
             <label className="LobbyStatus">{gameState.stage==="loading"?"Starting in "+startTimer+" seconds..." :"Waiting for players..."}</label>
      

        </div>
     


        <div  className={"InviteNotification"+(showInviteCopied?" show":"")}>Invite link copied to clipboard!</div>
        </div>

    
        <FriendInviteModal setShowModal={setShowFriendModal} shown={showFriendModal} friends={gameState?.persistedData?.localuserdata?.friends}/>
    </div>)
}