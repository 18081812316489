import { useEffect, useState,useRef } from "react";
import { useHomeContext } from "../Contexts/HomeContext";
import Game from "./Game";
import Home from "./Home"
import Lobby from "./Lobby"
import NotificationsDock from "./NotificationsDock";
import Profile from "./Profile";
import Replay from "./Replay";
import { protocol, endpoint } from "../Config/Endpoint";
import { useAuth0 } from "@auth0/auth0-react";
import { useWebSocketContext } from "../Contexts/WebSocketContext";
import accesssettings from "../Config/AccessSettings";


export default function Main(props){
    const {getAccessTokenSilently, user} = useAuth0()
    const prevEvent = useRef(null)
    const[page,setPage]=useState(1)
    const [statusMessage, setStatusMessage] = useState(null)
    const [showStatusMessage, setShowStatusMessage] = useState(null)
    const statusTimeout = useRef(null)
    const[transitioning, setTransitioning] = useState(false)
    
    const gameContext = useHomeContext()
    const webSocketContext= useWebSocketContext()
    const transitionPage = (func) =>{
        if(!transitioning){
            setTransitioning(true)
            setTimeout(()=>{
                func()
                setTimeout(()=>setTransitioning(false),0)},250)
        }
    }

    async function addFriend(friendid){
        let token = null
        try{
            token = await getAccessTokenSilently()
        }catch{
            token=null
        }

        try{
            const l = await fetch(protocol+endpoint+"/user/sendfriendrequest/"+friendid, { headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
            }, method:"POST", credentials:"include"})
            if(l.status===200){
        
                gameContext.dispatch({type:"friendcodesuccess"})
            }
           
        }catch(er){

    
        }
    }


    useEffect(()=>{
        if(webSocketContext?.state?.ready===true){
            webSocketContext?.dispatch({type:"initialize"})
        }

    }, [webSocketContext?.state?.ready])

    useEffect(()=>{
        const pathatjoin = window.location.pathname
        
        setTimeout(()=>{
            if(pathatjoin?.slice(1,9)==="profile/"){
                const userid = pathatjoin?.slice(9)
                if(userid?.length===9){
                    gameContext.dispatch({type:"selectprofile", payload:userid})
                }
            }else if(pathatjoin?.slice(1,8)==="replay/"){
                const matchid = pathatjoin?.slice(8)
                if(matchid?.length===12){
                    gameContext.dispatch({type:"selectreplay", payload:matchid})
                }
            }else if(pathatjoin.slice(1,11)==="addfriend/"){

                addFriend(pathatjoin.slice(11))
                    



            }
        },1000)
    }, [])

    useEffect(()=>{
        const stage=gameContext?.state?.stage

        if(gameContext?.state?.roomcode&&stage){
            
            if(stage==="lfp"||stage==="loading"){
                if(page!==2){
                    transitionPage(()=>setPage(2))
                }
                
                window.history.replaceState("Lobby", "Lobby", "/games/"+gameContext?.state?.roomcode)
            
            }else if(stage==="gamestarted"){
                transitionPage(()=>setPage(3))
                
            }else{
                transitionPage(()=>setPage(1))
                window.history.replaceState("Lobby", "Lobby", "/")
            }

        }else if(gameContext?.state?.selectedProfile!=null){
            transitionPage(()=>setPage(4))
            window.history.replaceState("Profile", "Profile", "/profile/"+gameContext?.state?.selectedProfile)
        }else if(gameContext?.state?.selectedReplay!=null){


            transitionPage(()=>setPage(5))
            window.history.replaceState("Profile", "Profile", "/replay/"+gameContext?.state?.selectedReplay)
        
        }else{
            transitionPage(()=>setPage(1))
            
            window.history.replaceState("Home", "Home", "/")
        }
    
    },[gameContext?.state?.stage, gameContext?.state?.roomcode,gameContext?.state?.selectedProfile, gameContext?.state?.selectedReplay])

    useEffect(()=>{
        const event = gameContext?.state?.passevent
        if(prevEvent.current!=null){   
        if(event){
            if(prevEvent.current!==event.id){
                
                        if(prevEvent.current==null){
                            prevEvent.current = event.id
                            return
                        }

            const data = event.payload   
            switch(event.type){
                case "statusnotif":{
    
                    setStatusMessage(data)
                    setShowStatusMessage(true)
                    clearTimeout(statusTimeout.current?.[0])

                    statusTimeout.current = setTimeout(()=>{setShowStatusMessage(null)

                        
                    
                    },4000)

                    break
                }
                default:{
                    break
                }

            }
            prevEvent.current=event.id
        }
            


        
        }
    }else{
        prevEvent.current = 0

    }

       

    }, [gameContext?.state?.passevent])




 




    return(<main className={transitioning?"transitioning":""}>

        

{page===1?<Home/>:(page===2?<Lobby/>:(page===3?<Game/>:(page===4?<Profile key={gameContext?.state?.selectedProfile} userid={gameContext?.state?.selectedProfile}/>:(page===5?<Replay matchid={gameContext?.state?.selectedReplay}/>:null))))}
    
      
    <NotificationsDock/>
    <div className={"StatusNotification"+(showStatusMessage===true?' show':"")}><label>{statusMessage}</label></div>
    
    </main>)
}



