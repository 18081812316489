import React, { memo } from "react"

export default memo( function Block(props){


    return(
        <div  onClick={props.onClick} className={"Block Color"+props.color}>
            <div></div>
        </div>
  


    )

})
