import { useWebSocketContext } from "../Contexts/WebSocketContext"
import { closeIcon } from "../Helpers/Icons"

export default function FriendInviteModal(props){
    const webSocketContext = useWebSocketContext()

    function sendGameInvite(friend,ev){
        if(ev){
            ev.target.classList.add("sent")
            setTimeout(()=>{ev.target.classList.remove("sent")}, 10000)
        }

        webSocketContext?.dispatch({type:"inviteplayer", payload:friend})

    }


    return(<div className={"FriendInviteModalContainer"+(props?.shown===true?" shown":"")}>
        <div className="FriendInviteModal">
        <label>Invite a friend</label>
        <img draggable={false} onClick={()=>props.setShowModal?.(false)} width="16px" height="16px" src={closeIcon}/>
        <div>
            {props.friends?.map((friend,ind)=>{


                return(<div className={friend.isonline?"online":""} key={ind}>
                    <div></div>
                    <label>{friend.name}</label>
                    <button onClick={(ev)=>sendGameInvite({name:friend.name, userID:friend.userID},ev)}><label>Invite</label><label className="sentlabel">Sent</label></button>

                </div>)
            })}


        </div>


        </div>


    </div>)
}