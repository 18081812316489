import React, {useEffect} from "react"
const defaultmatrix = [[null, 1,null], [1, 1,1] ]
export default function ZombiePiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("T4")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export{defaultmatrix}