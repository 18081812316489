import { useEffect, useState, useRef } from "react"
import { useHomeContext } from "../Contexts/HomeContext"


export default function Timer(props){
    const gameContext = useHomeContext()
    const [gameTimer, setGameTimer] = useState(null)
    const [timeLimit, setTimeLimit] = useState(null)
    const gameTimerTimeout = useRef(null)
    
    useEffect(()=>{
        if(gameContext.state?.timer){
            const time = (gameContext.state?.timer-Date.now())/1000
            setGameTimer(time)
            setTimeLimit(time)
    
        }
        
    
       
    
    }, [gameContext?.state?.timer])
    
    useEffect(()=>{
      clearTimeout(gameTimerTimeout.current)
        if(gameTimer>0){
          
           gameTimerTimeout.current=setTimeout(()=>{
              setGameTimer(Math.floor((gameContext.state?.timer-Date.now())/1000))
            }, 1000)
        }
    
        
    
       
    
    }, [gameTimer])




    return(
<div className="base-timer">
  <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g className="base-timer__circle">
      <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
      <path
        id="base-timer-path-remaining"
        strokeDasharray={(gameTimer/(60||timeLimit))*283 +" 283"}
        className="base-timer__path-remaining Red"
        d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
      ></path>
    </g>
  </svg>
  <span id="base-timer-label" className="base-timer__label">
      {parseInt(gameTimer)+"s"}
  </span>
</div>

    )
}