import { useState } from "react"
import { useWebSocketContext } from "../Contexts/WebSocketContext"
import {protocol, endpoint} from "../Config/Endpoint"
import {useAuth0} from "@auth0/auth0-react"


export default function CreateLobby(props){
    const [selectedMode, setSelectedMode] = useState(1)
    const [checkBoxChecked, setCheckBoxChecked] = useState(false)
    const [loading, setLoading]=useState(false)
    const webSocketContext = useWebSocketContext()
    const{getAccessTokenSilently} = useAuth0()

   
    const sendCreateRequest = async()=>{

        setLoading(true)



       




            let token
            try{
                token = await getAccessTokenSilently()
            }catch{
                try{
                  //  token = await getAccessTokenWithPopup(accesssettings)
                }catch{
                    token = null
                }
            }
            


        try{
            
        
         await fetch(protocol+endpoint+"/games/createlobby", { headers:{
            "Content-Type":"application/json", Authorization: `Bearer ${token}`,  
        },method:"POST", credentials:"include", body:(JSON.stringify({mode:selectedMode, isprivate:checkBoxChecked}))})
        




        }catch(error){
            console.log(error)
            setLoading(false)

        }
        
        


        

         setTimeout(()=>{
            setLoading(false)
        }, 2000)


    }

    return(
    <div className={"CreateLobbyDiv"+(loading?' loading':"")}>
        <ul>
            <li onClick={()=>setSelectedMode(1)} className={selectedMode===1?"selected":""}>Standard</li>
            <li onClick={()=>setSelectedMode(2)} className={selectedMode===2?"selected":""}>Duel</li>
            <li onClick={()=>setSelectedMode(3)}  className={selectedMode===3?"selected":""}>Threes</li>
            <li onClick={()=>setSelectedMode(4)} className={selectedMode===4?"selected":""}>Teams</li>

        </ul>


        <div onClick={()=>setCheckBoxChecked(prev=>!prev)} className={"Checkbox"+(checkBoxChecked?' checked':"")}>
            <label>Private</label>
            <div>

            </div>
        </div>
        <div onClick={sendCreateRequest} className={"CreateLobbyBtn"+(props.disabled?" disabled":"")} >

            
            <label>Create Lobby</label>
                <div className="loaderdiv">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

    </div>
    
    
    
    )
}