import React, {useEffect} from "react"
const defaultmatrix = [[1, 1], [1, 1] ]
export default function SquarePiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("O")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export {defaultmatrix}