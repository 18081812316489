import React from "react"
import './App.css';
import NavBar from "./Components/NavBar"
import Main from "./Components/Main"
import { HomeContextProvider } from "./Contexts/HomeContext";
import { WebSocketContextProvider } from "./Contexts/WebSocketContext";


function App() {
  return (

   
    <div className="App">
          <HomeContextProvider>
          <WebSocketContextProvider>
          <NavBar/>
          <Main/>
          </WebSocketContextProvider>
          </HomeContextProvider>

   

    </div>


  );
}

export default App;
