import React, { useState } from "react"
import { useWebSocketContext } from "../Contexts/WebSocketContext"
import { useHomeContext } from "../Contexts/HomeContext"
import { threesIcon, trophyIcon, verifiedIcon } from "../Helpers/Icons"
export default function PostGame(props){
const webSocketContext = useWebSocketContext()
const gameContext = useHomeContext()
    const homeContext = useHomeContext()
    const [selectedUser, setSelectedUser] = useState(null)


    return(<div className="postgamecontainer">

        <div className="postgamemodal">
            <label>GAME OVER</label>
            <div className="players">
            {props.game?.scoreboard?.map?.((ply, index)=>{

                return(<div onClick={()=>setSelectedUser(index)} key={index} className={""+(ply.won?" winner":"")+(" Color"+ply.color)+((ply?.userid&&ply?.userid?.charAt?.(0)!=="$")?" isuser":"")+(index===selectedUser?" selected":"")+(homeContext?.state?.persistedData?.localuserdata?.userID===ply.userid?" islocalplayer":"")}>
                    {ply.won?<img width={"16px"} height={"16px"} draggable="false" alt="trophy" src={trophyIcon}></img>:null}
                    <div className="nametag">
                    <div className={"Color"+ply.color}></div>
                    <label className="name">{ply.name}</label>
                    <img src={verifiedIcon} width={"12px"} height={"12px"}/>
                    <label className="score">{ply.score}</label>
                    </div>
                    <div className="userinfo">

                        <button onClick={()=>homeContext?.dispatch({type:"selectprofile", payload:ply.userid})}>View profile</button>
                        <label>{ply.username}</label>
                        <div className="stats">
                            <div> <div className="teamsicon"><div><div></div><div></div></div><div><div></div><div></div></div></div>  <label>{(Math.ceil((ply?.stats?.teams?.won??0)/ply?.stats?.teams?.played*100)||"0")+"%"}</label></div>
                            <div> <img src={threesIcon} width="10px" height="10px"/> <label>{(Math.ceil((ply?.stats?.threes?.won??0)/ply?.stats?.threes?.played*100)||"0")+"%"}</label></div>
                            <div> <div className="duelsicon"><div></div><div></div></div> <label>{(Math.ceil((ply?.stats?.duel?.won??0)/ply?.stats?.duel?.played*100)||"0")+"%"}</label></div>
                            <div> <div className="standardicondiv"><div><div></div><div></div></div><div><div></div><div></div></div></div>  <label>1000</label></div>
                        </div>
                    </div>


                </div>)





            })
            
            
            
            
            
            }



            </div>

            <div className="btns"> <button onClick={()=>gameContext?.dispatch({type:"selectreplay", payload:props.game?.gameid})} className="reviewbtn">Review game</button>  <button onClick={()=>{webSocketContext.dispatch({type:"leavegame"})}}>Play again</button></div>
        </div>

    </div>)
}






