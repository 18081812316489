import {createContext, useContext, useReducer } from "react";


function decodeTeams(num){
    switch(num){
        case 1:{
            return([0,0,1,1])
        }
        case 2:{
            return([1,1,0,0])
        }
        case 3:{
            return([1,0,1,0])
        }
        case 4:{
            return([0,1,0,1]) 
        }
        case 5:{
            return([0,1,1,0])    
        }
        case 6:{
            return([1,0,0,1])    
        }
        default:{
            return null   
        }
}
}

const HomeContext = createContext({})

export const HomeContextProvider = ({children}) =>{
    
    const homeContextReducer = (state, action) =>{


        switch(action.type){
            case"playingfor":{
                return({...state, playingFor:action.payload})  
            }
            case  "joingame" :{
                const playersmap=new Map()
                action.payload.players.forEach?.((ply)=>{
                    playersmap.set(ply.plyid, ply)

                }
                
                )
                const boxteams = decodeTeams(action.payload.boxteams)
              

                        
                    
                
            
               


                
                return({...state, ...action.payload, players:playersmap, boxteams:boxteams})
            }
            
            case  "setcolor1" :{
                const newplayers = new Map(state.players)
                const ply = newplayers.get(action.payload.plyid)
                const colors = [...state.colors]
                
                
                if(ply?.color1!=null){
                    colors[ply.color1-1]=null
                    colors[action.payload.color-1] = {ply:ply.plyid, num:1}
                }
                if(ply!=null){
                    ply.color1=action.payload.color
    
                }
    
                return({...state, colors:colors, players:newplayers})

          
            }
            case  "setcolor2" :{
                const newplayers = new Map(state.players)
                const ply = newplayers.get(action.payload.plyid)
                const colors = [...state.colors]
                
                if(ply?.color2!=null){
                    colors[ply.color2-1]=null
                    colors[action.payload.color-1] = {ply:ply.plyid, num:2}
                }
                if(ply!=null){
                    ply.color2=action.payload.color
    
                }
    
                return({...state, colors:colors, players:newplayers})

          
            }
            case  "changename" :{
                const newstate={...state}
                const newplayers = new Map(newstate.players)
                const player = newplayers.get(action.payload.plyid)
                
                if(player){
                    player.name=action.payload.name
                }
                return({...newstate})
            }
            case "newteams":{
                const newboxteams = decodeTeams(action.payload.boxteams)
                return({...state, boxteams:newboxteams})

            }
            case"changestage":{
                return({...state, stage:action.payload.stage, timer:action.payload.timer})
            }
            case"playerjoin":{

                if(state?.colors==null)return(state)
                const ply = action.payload.player
                const newplayers = new Map(state.players)
                const colors = [...state.colors]

                newplayers.set(ply.plyid, ply)



                if(ply?.color1!=null){
                   
                    colors[ply?.color1-1] = {ply:ply.plyid, num:1}
                }


                if(ply?.color2!=null){
                   
                    colors[ply?.color2-1] = {ply:ply.plyid, num:2}
                }


                return({...state,players:newplayers, colors:colors})
            }
            case"playerleave":{
                const newplayers = new Map(state?.players)
                const colors = (state.colors!=null?[...state?.colors]:[])
                const ply = newplayers.get(action.payload.playerid)

                if(ply?.color1!=null){
                    colors[ply.color1-1]=null
                 
                }


                if(ply?.color2!=null){
                    colors[ply.color2-1]=null
                   
                }
                newplayers.delete(action.payload.playerid)

                if(action.payload.playerid===state.localplayer){

                    if(action.payload.type==="kicked"){



   
                        return({persistedData:{...state.persistedData}, newnotification:{type:"default",message:"You have been kicked.",time:5, action:null} })
                    }else{
                        return({persistedData:{...state.persistedData}})
                    }

                    
                }else{
                    return({...state,players:newplayers, colors:colors, leader:action.payload.leaderid})
                }



                
            }
            case "gamestart":{
                
                const playersmap=new Map()
                

                action.payload.players.forEach?.((ply)=>{
                    playersmap.set(ply.plyid, ply)

                })
                const playingfor = playersmap.get(state.localplayer)?.playingfor
               

                return({...state, ...action.payload, playingfor:playingfor ,players:playersmap})
            }
            case "placepiece":{
     
                const {corner, name, orientation, coordinates} = action.payload
                const id = (state?.passevent?.id!=null? state.passevent.id+1:1)

                const newcorners = [...state.corners]
              const newpieces={...newcorners[corner].pieces} 
             const encodedinfo = (""+Number(orientation.flipped)+orientation.rotation+(coordinates[0]<10?"0"+coordinates[0]:coordinates[0] )+(coordinates[1]<10?"0"+coordinates[1]:coordinates[1] ))

             newpieces[name]=encodedinfo
             const newcorners2 = [...newcorners.slice(0,corner), {...newcorners[corner], pieces:newpieces}, ...newcorners.slice(corner+1)]



               


                return({...state,  corners:newcorners2,passevent:{id:id, type:"placepiece", payload:action.payload}})


            }
            case "waitforturn":{

         
                const id = (state?.passevent?.id!=null? state.passevent.id+1:1)

                return({...state, passevent:{id:id, type:"waitforturn"}})
            }
            case "cannotplace":{

         
                const id = (state?.passevent?.id!=null? state.passevent.id+1:1)

                return({...state, passevent:{id:id, type:"cannotplace"}})
            }
            case "startturn":{
                let tempcontroller
                let postsurrendercorners
                const player = state?.players?.get(action.payload.newplayingfor?.plyid)
                if(player?.playingfor!==action.payload.newplayingfor?.corner){
                    tempcontroller = {...state.tempcontroller, [action.payload.newplayingfor?.corner]:{ name:player?.name}}


                }
          

                const surrenderedcorner = action.payload.surrendercorner

                if(surrenderedcorner!=null){
                    const newcorners = [...state.corners]
                    newcorners[surrenderedcorner] = {...newcorners[surrenderedcorner], surrendered:true}
                    postsurrendercorners=newcorners

               
                }


                if(state.localplayer===action.payload.newplayingfor?.plyid){
          
                        if(state.playingfor!==action.payload.newplayingfor?.corner){

                            return({...state, curcorner:action.payload.curcorner, playingfor:action.payload.newplayingfor?.corner, timer:action.payload.timer, corners:(postsurrendercorners!=null?postsurrendercorners:state.corners)})
                        }
                }
                if(tempcontroller){
                    return({...state, curcorner:action.payload.curcorner, timer:action.payload.timer, tempcontroller:tempcontroller, corners:(postsurrendercorners!=null?postsurrendercorners:state.corners)})
                
                }else{
                    return({...state, curcorner:action.payload.curcorner, timer:action.payload.timer,corners:(postsurrendercorners!=null?postsurrendercorners:state.corners)})
                }

                
            }
            case "playerleavesurrender":{
                const id = (state?.passevent?.id!=null? state.passevent.id+1:1)
                const newcorners = [...state.corners]
                action.payload.corners?.forEach?.(index=>{
                    newcorners[index].surrendered = true

                })



                return({...state, passevent:{id:id, type:"playersurrendered", payload:action.payload.plyid}, corners:newcorners})
            }
            case "gameend":{
                
                return({...state, enddata:{roomcode:action.payload.roomcode, gameid:action.payload.gameid, scoreboard:action.payload.scoreboard}})

            }
            case 'selectprofile':{
               
                return({persistedData:state.persistedData,selectedProfile:action.payload})
            }

            case "leavegame" :{
                window.history.replaceState("Home", "Home", "/")
                return({persistedData:{...state?.persistedData}})
            }
            case "setLocalUserData":{

                return({...state, persistedData:{...state?.persistedData, localuserdata:action.payload}})
            }
            case "setfriends":{

                return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, friends:action.payload}}})
            }
            case "removefriend":{
                const friends = state?.persistedData?.friends?.filter(friend=>friend.userID!=action.payload)
                return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, friends:friends}}})
            }
            case "acceptfriendrequest":{

                const receivedrequests = state?.persistedData?.localuserdata?.receivedRequests?.filter(request=>request.userID!=action.payload?.userID)
                return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, receivedRequests:receivedrequests,friends:[...state?.persistedData?.localuserdata?.friends, action.payload]}}})
            }
            case "removereceivedrequest":{
                const receivedrequests = state?.persistedData?.localuserdata?.receivedRequests?.filter(request=>request.userID!=action.payload)
                return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, receivedRequests:receivedrequests}}})
            }
            case "removesentrequest":{
                const sentrequests = state?.persistedData?.localuserdata?.receivedRequests?.filter(request=>request.userID!=action.payload)
                return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, sentRequests:sentrequests}}})
            }
            case "selectreplay":{
                return({persistedData:state.persistedData,selectedReplay:action.payload})
            }
            case "replaynotfound":{
                return({...state, selectedReplay:null})
            }
            case "setreplaycorners":{
                return({...state, corners:action.payload})
            }
            case "friendcodesuccess":{
                
                return({...state, newnotification:{type:"default",message:"Friend request sent.",time:5, action:null}})
            }
            case "playerinvited":{
                
                return({...state, newnotification:{type:"default",message:action.payload+" invited.",time:5, action:null}})
            }
            case "gameinvite":{
                if(action.payload.roomcode!==state?.roomcode)return({...state, newnotification:{type:"gameinvite",info:action.payload,time:5000, action:null} })
                return(state)
                
            }
            case "friendonline":{
                const {userid}=action.payload

                if(userid){
                    const friends= [...state?.persistedData?.localuserdata?.friends]
                    const l = friends?.find?.(friend=>friend.userID===userid)
                    if(l)l.isonline=true
                    
                    return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, friends:friends}}})
                }
                return(state)
            }
            case "friendoffline":{
                const {userid}=action.payload
                if(userid){
                    const friends= [...state?.persistedData?.localuserdata?.friends]
                    const l = friends?.find?.(friend=>friend.userID===userid)
                    if(l)l.isonline=false
                    
                    return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, friends:friends}}})
                }
                return(state)
            }
            case "playerremoved":{

                const friends = state?.persistedData?.friends?.filter?.(friend=>friend.userID!=action.payload?.userid)
                if(friends!=null)return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, friends:friends}}})
                return(state)
            }
            default:{
                return(state)
            }
            case "playeradded":{
                const sentRequests = (state?.persistedData?.localuserdata?.sentRequests?.filter?.(request=>request.userID!=action.payload?.userID)||[])
                return({...state, persistedData:{...state?.persistedData, localuserdata:{...state?.persistedData?.localuserdata, sentRequests:sentRequests,friends:[...state?.persistedData?.localuserdata?.friends, action.payload]}}})
            }
        }
    }

    const [state, dispatch]=useReducer(homeContextReducer,  {})

    const HomeContextStore = {state, dispatch}

    return(<HomeContext.Provider value={HomeContextStore}>{children}</HomeContext.Provider>)
}




export const useHomeContext = () =>{
    return(useContext(HomeContext))

}
