
import { useEffect,  useState } from "react"
import { closeIcon, doneIcon } from "../Helpers/Icons"
export default function Notification(props){
    const [shown,setShown] = useState(false)
 
    useEffect(()=>{
        setTimeout(()=>setShown(true),5)
        const timeout = setTimeout(()=>setShown(false), (props.data.time*1000)-250)
        return(()=>{
            clearTimeout(timeout)
        } )
    }, [])


    async function acceptGameInvite(){

        await props.sendJoinRequest?.(props.data?.info?.roomcode)
        removeSelf()        
    }


    function removeSelf(){
        setShown(false)
        setTimeout(()=>props.removeNotif(), 250)
        
    }



    return(<div  className={"Notification"+(shown===true?" shown":"")}>
        {props.data?.type==="default"?<label>{props.data?.message}</label>:(props.data?.type==="gameinvite"?
        <div className="invitenotification">
            <label className="plyname">{props.data?.info?.name}</label>
            <label>{props.data?.info?.gamemode}</label>
            <div><div onClick={acceptGameInvite}><img width="18px" height="18px" src={doneIcon}/></div><div onClick={removeSelf}><img width="12px" height="12px"  src={closeIcon}/></div></div>
        </div>
        
        :null)}

    </div>)
}