import { useAuth0 } from "@auth0/auth0-react"
import { useState, useEffect, useRef } from "react"
import { useHomeContext } from "../Contexts/HomeContext"
import { useWebSocketContext } from "../Contexts/WebSocketContext"
import Notification from "./Notification"
import { protocol, endpoint } from "../Config/Endpoint"
import accesssettings from "../Config/AccessSettings"

export default function NotificationsDock(props){
    const {getAccessTokenSilently} = useAuth0()
    const homeContext = useHomeContext()
    const webSocketContext = useWebSocketContext()
    const [notifications, setNotifications]=useState([])
    const currentNotifId = useRef(1)
    
   function removeNotification(notifid){
        
    setNotifications?.(prev=>{
        const index = prev.findIndex((notification)=>notification.id===notifid)
        if(index===-1)return prev
        const newarray =  [...prev.slice(0,index), ...prev.slice(index+1)]
        return newarray
})


   }


   async function acceptRequest(roomcode){


    let token = null
    try{
        token = await getAccessTokenSilently()
    }catch{
        token=null
    }
    const l = await fetch(protocol+endpoint+"/games/joinlobby/"+roomcode, { headers:{ Authorization: `Bearer ${token}`,
    "Content-Type":"application/json",   
    }, method:"POST", credentials:"include"})

    console.log(l.status)
    if(l.status!==200){

    return false
    }


    webSocketContext?.dispatch({type:"joingame"})


   }


    useEffect(()=>{
        const notif = homeContext?.state?.newnotification
        if(notif){
            setNotifications(prev=>{
                const notifid = currentNotifId.current++
                const obj = {...notif, id:notifid}
                const l = [...prev]
                l.push(obj)
                setTimeout(()=>{
                    removeNotification(notifid)
                }, notif.time*1000)
                return l
            })
        }
  


    

    }, [homeContext?.state?.newnotification])




  

    return(<div className="Notifications">
        {notifications?.map?.((notif, index)=>{
            return(
           
                
                <Notification key={notif.id} sendJoinRequest={acceptRequest} removeNotif={()=>{removeNotification(notif.notifid)}} data={notif}/>
            
            
            )



        })


        }


    </div>)
}