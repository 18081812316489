import {createContext, useContext, useReducer, useEffect } from "react";
import { useHomeContext } from "./HomeContext";
import { endpoint } from "../Config/Endpoint";

const WebSocketContext = createContext({})

export const WebSocketContextProvider = ({children}) =>{
    const gameContext = useHomeContext()
    const webSocketContextReducer = (state, action) =>{

       
        switch (action.type){
           
            case "initialize":{
                

   
                    state?.socket?.close?.()
                    const socket =  new WebSocket("wss://"+endpoint)
                    socket.addEventListener("open", ()=>{
                        dispatch({type:"connect", payload:socket})
                       
                    })
                    socket.addEventListener("close",()=>{
                        dispatch({type:"close"})

                 
                    })
                    return({...state, socket:"Connecting"})
                    
                

                
            }
                

            
            case "connect":{

                let socketreadyresolve
                
                const socketReady = new Promise((resolve, reject)=>{

                    socketreadyresolve=resolve
                    setTimeout(()=>{reject()},10000)

                })


                action.payload.onmessage =  (ev)=>{
                    const {type,data} = JSON.parse(ev.data)
            
                        if(type!=null && data!=null){
              

                            switch(type){
                                case "playerremoved":{
                                    gameContext.dispatch({type:"playerremoved", payload:{userid:data.userid}})
                                    break
                                }
                                case "friendonline":{
                                    gameContext.dispatch({type:"friendonline", payload:{userid:data.userid}})
                                    break
                                }
                                case "friendoffline":{
                                    gameContext.dispatch({type:"friendoffline", payload:{userid:data.userid}})
                                    break
                                }
                                case "gameinvite":{
                                    gameContext.dispatch({type:"gameinvite", payload:{roomcode:data.roomcode, name:data.name, gamemode:data.gamemode}})
                                    break

                                }
                                case "joingame" :{
                                    gameContext.dispatch({type:"joingame", payload:{localplayer:data.localplayer,leader:data.leader, gamemode:data.gamemode,corners:data.corners,players:data.players, stage:data.stage,turn:data.turn,playingfor:data.playingfor ,eventlog:data.eventlog, roomcode:data.roomcode, isprivate:data.isprivate, colors:data.colors, timer:data.timer, boxteams:data.boxteams} })
                                    break
                                }
                                case "rejoin" :{
                                    gameContext.dispatch({type:"joingame", payload:{localplayer:data.localplayer,curcorner:data.curcorner,leader:data.leader, gamemode:data.gamemode,corners:data.corners,players:data.players, stage:data.stage,turn:data.turn,playingfor:data.playingfor ,eventlog:data.eventlog, roomcode:data.roomcode, isprivate:data.isprivate, colors:data.colors, timer:data.timer} })
                                    break
                                }
                                case "setcolor1" :{
                                    gameContext.dispatch({type:"setcolor1", payload:{plyid:data.plyid, color:data.color}})
                                    break
                                }
                                case "setcolor2" :{
                                    gameContext.dispatch({type:"setcolor2", payload:{plyid:data.plyid, color:data.color}})
                                    break
                                }
                                case "setname" :{
                                    gameContext.dispatch({type:"changename", payload:{plyid:data.plyid, name:data.name}})
                                    break
                                }
                                case "newteams":{
                                    gameContext.dispatch({type:"newteams", payload:{boxteams:data.boxteams}})
                                    break
                                }
                                case "stagechange":{
                                    gameContext.dispatch({type:"changestage", payload:{stage:data.stage, timer:data.timer}})
                                
                                    break
                                }
                                case "playerjoin":{
                                    gameContext.dispatch({type:"playerjoin", payload:{player:data.player}})
                                    break
                                }
                                case "playerleave":{
                                    
                                    gameContext.dispatch({type:"playerleave", payload:{playerid:data.playerid, leaderid:data.leaderid, type:data.type}})
                                    break
                                }
                                case "gamestart":{
                                
                                    gameContext.dispatch({type:"gamestart", payload:{players:data.players, corners:data.corners, timer:data.timer, curcorner:data.curcorner, stage:data.stage}})
                                    break
                                }
                                case "placepiece":{
                                        
                                    gameContext.dispatch({type:"placepiece", payload:{corner:data.corner, name:data.name, orientation:data.orientation, coordinates:data.coordinates}})
                                    break
                                }
                                case "waitforturn":{
                                    
                                    gameContext.dispatch({type:"waitforturn"})
                                    break
                                }
                                case "cannotplace":{
                                    
                                    gameContext.dispatch({type:"cannotplace"})
                                    break
                                }
                                case "startturn":{
                                    
                            
                                    gameContext.dispatch({type:"startturn", payload:{timer:data.timer, newplayingfor:data.newplayingfor, curcorner:data.curcorner, surrendercorner:data.surrendercorner}})
                                    break
                                }
                                case 'playerleavesurrender':{
                                    gameContext.dispatch({type:"playerleavesurrender", payload:{plyid:data.plyid, corners:data.corners}})
                                    break
                                }
                                case "gameend":{
                                    gameContext.dispatch({type:"gameend", payload:{roomcode:data.roomcode, gameid:data.gameid,scoreboard:data.scoreboard}})
                                    break
                                }
                                case "readyformsgs":{
                                    socketreadyresolve()
                                    break
                                }

                                default:
                                    
                                    break
                            }
                                


                            }

        
                    }

                    socketReady.then(()=>{
                        dispatch({type:"joingame"})}).catch((error)=>{
                            
                            

                        })
            
                
                    
        
                    return({...state, socket: action.payload})

               
            }
            case "rejoin":{
                state?.socket?.send?.(JSON.stringify({type:"rejoin", data:null}))
                return(state)
            }    
        
           case "joingame":{
                        state?.socket?.send?.(JSON.stringify({type:"readyforjoin", data:null}))
                return(state)
           }
           case "inviteplayer":{

            if(state?.socket!=null){
                setTimeout(()=>{gameContext?.dispatch({type:"playerinvited", payload:action.payload.name})},0)
                state.socket.send?.(JSON.stringify({type:"inviteplayer",  data:{userid:action.payload?.userID, senderid:gameContext?.state?.persistedData?.localuserdata?.userID}}))
        }
                return(state)
           }

            case "close":{
                setTimeout(()=>gameContext.dispatch({type:"leavegame"}), 0)
                return({...state, socket: null})
                
            }
            case "leavegame":{
   
                state?.socket?.send?.(JSON.stringify({type:"leavegame", data:null}))
                setTimeout(()=>gameContext.dispatch({type:"leavegame"}), 0)
                return(state)
            }


            
            case "setColor1":{
                
                if(state?.socket!=null){
                    
                    state.socket.send(JSON.stringify({type:"setcolor1", data:action.payload}))

                
                }
                return(state)
                
            }
            case "setColor2":{
                if(state?.socket!=null){
                    state.socket.send(JSON.stringify({type:"setcolor2", data:action.payload}))

                }
                return(state)
                
            }
            case "kickPlayer":{
                if(state?.socket!=null){
                    state.socket.send(JSON.stringify({type:"kickplayer", data:action.payload}))

                }

                return(state)
            }

            case "shuffleTeams":{
                if(state?.socket!=null){
                    state.socket.send(JSON.stringify({type:"shuffleteams", data:null}))

                }
                return(state)

            }

            case "changeName":{
                if(state?.socket!=null){
                    state.socket.send(JSON.stringify({type:"changename", data:action.payload}))

                }
                return(state)
                
            }
            case "placepiece":{
          
                if(state?.socket!=null){
                    state.socket.send(JSON.stringify({type:"placepiece", data:action.payload}))
                }
                return(state)
            }
            case "socketlink":{
                const userid=gameContext?.state?.persistedData?.localuserdata?.userID
                if(userid&&state?.socket!=null){
                    if(userid.charAt(0)!=="$"){
                    state.socket.send?.(JSON.stringify({type:"socketlink", data:{userid:userid}}))
                    if(state?.joinpending===true){
                        state.socket.send?.(JSON.stringify({type:"readyforjoin", data:null}))
                        return({...state, joinpending:false,linked:true})
                        
                    }
                    return({...state, linked:true})
                    }
                }

                return(state)
            }
            case "setready":{



                return({...state, ready:true})
            }
            default:{
                return(state)
            }



           
        }





    }
    useEffect(()=>{
        
        if(gameContext?.state?.persistedData?.localuserdata?.userID!=null){
           
                dispatch({type:"socketlink", payload:"s"})

            
        }
       
    },
    [gameContext?.state?.persistedData?.localuserdata?.userID])

    const [state, dispatch]=useReducer(webSocketContextReducer,  {})

    const webSocketContextStore = {state, dispatch}

    return(<WebSocketContext.Provider value={webSocketContextStore}>{children}</WebSocketContext.Provider>)
}




export const useWebSocketContext = () =>{

    return(useContext(WebSocketContext))

}
