import { useRef, useState, useEffect } from "react"


export default function CustomInput(props){
    const input = useRef(null)
    const [textInside, setTextInside]=useState(false)
    const [showError, setShowError]=useState(false)

    function checkEnter(ev){
        if(ev.key === 'Enter' || ev.keyCode === 13){
            ev.preventDefault()
            input.current.blur()
            if(input.current.value.length>0)props.function(input.current.value)
            

        }
       

    }

    function inputChange(ev){
        if(showError){
            setShowError(false)
        }

        if (ev.target.value===""){
            setTextInside(false)
        }else{
            setTextInside(true)
        }

    }

    useEffect(()=>{
        if(props.event===true){
            if(input.current.value.length>0)props.function(input.current.value)
        }
    }, [props.event])

    
    useEffect(()=>{
        if(props.errorevent===true){
            setShowError(true)
        }
    }, [props.errorevent])

    return(<div className={"blokioinput"+( textInside?" textinside":"")+(showError?" error":"")}>

        <input onKeyDown={checkEnter} maxLength={16} disabled={props.disabled} ref={input} onChange={inputChange}></input>

        <label>{props.placeholder}</label>

    </div>)



}