import React, {useState, useEffect, useRef} from "react"
import PlusPiece from "./PlusPiece";
import LPiece from "./LPiece";
import SwordsmanPiece from "./SwordsmanPiece";
import HelmetPiece from "./HelmetPiece";
import LongboyPiece from "./LongboyPiece";
import OnerPiece from "./OnerPiece";
import SPiece from "./SPiece";
import StickPiece from "./StickPiece";
import ToePiece from "./ToePiece";
import WormPiece from "./WormPiece";
import ZombiePiece from "./ZombiePiece";
import TPiece from "./TPiece";
import StairPiece from "./StairPiece";
import CarPiece from "./CarPiece";
import ArmPiece from "./ArmPiece";
import AdoptedPiece from "./AdoptedPiece";
import BalancePiece from "./BalancePiece";
import BigLPiece from "./BigLPiece";
import BatonPiece from "./BatonPiece";
import FingerPiece from "./FingerPiece";
import GlockPiece from "./GlockPiece";
import SquarePiece from "./SquarePiece";
import { useHomeContext } from "../Contexts/HomeContext";
import StaticPiece from "./StaticPiece";
import Timer from "./Timer";
import { crossIcon } from "../Helpers/Icons";



export default function Hand(props){



  const valueMapping = {
    "X": 5,
    "V3" :3,
    "N":  5,
    "U":   5,
    "I5":  5,
    "1": 1,
    "Z4": 4,
    "I4": 4 ,
    "2": 2 ,
    "Z5" : 5,
    "T4": 4,
    "T5": 5 ,
    "W": 5,
    "P": 5,
    "L5": 5,
    "F": 5,
    "A" : 5,
    "V5": 5,
    "Y": 5,
    "I3":3,
    "L4":  4,
   "O":4



}

  const componentMapping = {
    "X": PlusPiece,
    "V3" :LPiece,
    "N":  SwordsmanPiece,
    "U":   HelmetPiece,
    "I5":  LongboyPiece,
    "1": OnerPiece,
    "Z4": SPiece,
    "I4": StickPiece ,
    "2": ToePiece ,
    "Z5" : WormPiece,
    "T4": ZombiePiece,
    "T5": TPiece ,
    "W": StairPiece,
    "P": CarPiece,
    "L5": ArmPiece,
    "F": AdoptedPiece,
    "A" : BalancePiece,
    "V5": BigLPiece,
    "Y": BatonPiece,
    "I3":FingerPiece,
    "L4":  GlockPiece,
   "O":SquarePiece



}

const prevEvent = useRef(null)
  const handRef = useRef(null)
    const gameContext = useHomeContext()
  
    const [shownHand, setShownHand]= useState(null)
    const hoverdiv = useRef(null)
    const [handTotals, setHandTotals] = useState({0:0, 1:0, 2:0, 3:0})

    
    const [rotateFlip, setRotateFlip] = useState(0)

   function findnextavailpiece(direction, index){
    const keys =  Object.keys(gameContext?.state?.corners[gameContext.state.playingfor].pieces)

    if(index+direction<0){
      index =keys.length
      return findnextavailpiece(direction,index+direction+1)
    }else if(keys.length<index+direction){
      index = -2
      return findnextavailpiece(direction,index+direction)
    }

      const piecedata = gameContext?.state?.corners[gameContext.state.playingfor].pieces[keys[index+direction]]
    
      if(piecedata===0){
    
        return keys[index+direction]
      }else{
        return findnextavailpiece(direction,index+direction)
      }


    }


    useEffect(()=>{
      if(props.pieceChange===1){
          const ind= Object.keys(gameContext?.state?.corners[gameContext.state.playingfor].pieces).findIndex((piecename)=>piecename===props.selectedPieceInfo?.name)
          if(ind!==-1){
            const piecename = findnextavailpiece(1,ind)
            props.setSelectedPieceInfo({name:piecename, orientation:{rotation:0, flipped:false}, corner:gameContext?.state.playingFor})
           
          }
      }else if(props.pieceChange===-1){
        const ind= Object.keys(gameContext?.state?.corners[gameContext.state.playingfor].pieces).findIndex((piecename)=>piecename===props.selectedPieceInfo?.name)
         
        if(ind!==-1){
           const piecename=  findnextavailpiece(-1,ind)
           props.setSelectedPieceInfo({name:piecename, orientation:{rotation:0, flipped:false}, corner:gameContext.state.playingfor})

        }

      }
    }, [props.pieceChange])


    useEffect(()=>{
      setRotateFlip(0)
    }, [rotateFlip])






    useEffect(()=>{
      

        const x = shownHand

        if(hoverdiv.current!=null){
          hoverdiv.current.style.left = (x)*29+"px"
        }



    }, [shownHand])


    useEffect(()=>{
      

    

      if(gameContext.state.playingfor!=null){
        setShownHand(gameContext.state.playingfor)
      }

      props.setSelectedPieceInfo(null)

  }, [gameContext.state.playingfor])


  useEffect(()=>{
    if(gameContext.state.stage!=="gamestarted")return

    setHandTotals(prev=>{
      const newHandTotals = {...prev}
      gameContext?.state?.corners.forEach((corner, ind)=>{
        let total = 0
        for(const[name, value] of Object.entries(corner.pieces)){
            if(value===0){
              total+=valueMapping[name]
            }

        }

        newHandTotals[ind]=total
        
    })

    return newHandTotals
    })


    

}, [gameContext.state.stage])


useEffect(()=>{
  const event = gameContext?.state?.passevent
  if(prevEvent.current!=null){
          
  
  if(event){
      if(prevEvent.current!==event.id){
          
                  if(prevEvent.current==null){
                      prevEvent.current = event.id
                      return
                  }
      const data = event.payload
      switch(event.type){
          case "placepiece":{
  
              setHandTotals(prev=>{
                const newtotals = {...prev}
                newtotals[data.corner]-=valueMapping[data.name]
                return(newtotals)
              })
              break
          }
          default:{

            break
          }
      }
      prevEvent.current=event.id
  }

  }
}else{
  prevEvent.current = 0

}
}, [gameContext?.state?.passevent])


  

   function setSelectedPieceF(pieceName, pieceOrientation, corner){
    props.setSelectedPieceInfo({name:pieceName, orientation:pieceOrientation, corner:corner})
   }

    return(
      <React.Fragment>
      <div className="TurnTimerDiv"><label className={"Color"+gameContext.state?.corners?.[gameContext.state?.curcorner]?.color}>{(gameContext.state?.tempcontroller?.[gameContext.state?.curcorner]?.name||gameContext.state?.players?.get?.(gameContext.state?.corners?.[gameContext.state?.curcorner]?.plyid)?.name)+"'s turn."}</label> <Timer/> </div>
    <div className="HandContainer">
 
    <div ref={handRef} className="Hand">
      
      <div onPointerLeave={()=>setShownHand(gameContext.state.playingfor)} className="peekbar">

        {gameContext?.state.corners?.map?.((cornerinfo, key) => {
          return(
          <div key={key} onPointerOver={(event)=>{setShownHand(key)}}  className={"colorpeekbtn"+(cornerinfo.surrendered===true?" surrendered":"")}> <div className={"Color"+cornerinfo.color}></div> <img src={crossIcon} alt="Player surrendered" width={"14px"} height={"14px"}></img></div>)

          
        })}

        <div ref={hoverdiv} className="hover" ></div>

      </div>
          <label className={"pointslabel"}>{handTotals[shownHand]}</label>
          
          <div className="pieces">
          <div className={"nohand "+(shownHand===false?"shown":"")}>You are out of the game.</div>
          {gameContext.state?.corners?.map((cornerdata, key)=>{
          
          return(
          
          
         
          <div className={((shownHand===key)?"shown":"")+ (key===gameContext.state.playingfor?" ownhand":"") } key={key}>{
          
          
          Object.keys(cornerdata.pieces).map((piecename, x)=>{
             
            return(

            <StaticPiece key={x} pieceOrientation={{flipped:false,rotation:0} } alreadyused={cornerdata.pieces[piecename]!==0} pieceCorner={key} selectedPiece={props.selectedPieceInfo?.name} setPieceSelected={setSelectedPieceF}>
                   {React.createElement(componentMapping[piecename])}
              </StaticPiece>)

          })}</div>)
        })}


          
          </div>

     

          <div className={"SelectionControl"+(props.selectedPieceInfo?.name!=null?" show":"")}>
            <button onClick={()=>setRotateFlip(-1)} className="flip"/>

            <button onClick={()=>setRotateFlip(1)} className="rotate"/>

          </div>

    </div>
  
    </div>
    </React.Fragment>
    )
}