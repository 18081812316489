import { useEffect, useState } from "react"
import { protocol, endpoint } from "../Config/Endpoint"
import { useAuth0 } from "@auth0/auth0-react"
import { useHomeContext } from "../Contexts/HomeContext"
import { closeIcon, arrowIcon, doneIcon } from "../Helpers/Icons"
import accesssettings from "../Config/AccessSettings"

export default function DashboardFriendsList(props){
    const [selectedTab, setSelectedTab] = useState(1)
    const {getAccessTokenSilently}=useAuth0()
    const homeContext= useHomeContext()
    async function SendFriendAction(action,userid){
  
        try{
         
            const token = await getAccessTokenSilently()
            const req = await fetch(protocol+endpoint+"/user/friend/"+action, { body:JSON.stringify({userid:userid}), headers:{ Authorization: `Bearer ${token}`,
            "Content-Type":"application/json",   
            }, method:"PATCH",  credentials:"include"})
         


                if(req.status!=500){
                    if(action==="remove"){
                        homeContext?.dispatch({type:"removefriend", payload:userid})
                    }else if(action==="acceptfr"){
                        const user = await req.json()
                        homeContext?.dispatch({type:"acceptfriendrequest", payload:user})
                    }else if(action==="denyfr"){
                        homeContext?.dispatch({type:"removereceivedrequest", payload:userid})
                    }else if(action==="cancelfr"){
                        homeContext?.dispatch({type:"removesentrequest", payload:userid})
                    }
                    
                }



                return(req.status)
        


            

        }catch(error){


            return(false)
        }



    }


    return(<div className="DashboardFriendsList">
    <img onClick={()=>props.exitFriendsList()} width={"20px"} height={"20px"} src={arrowIcon}/>
    <div className="TabBtns"><button onClick={()=>setSelectedTab(1)} className={(selectedTab===1?"selected":"")}>Friends</button><button onClick={()=>setSelectedTab(2)} className={(selectedTab===2?"selected":"")}>Requests</button></div>
    <div className={"ContentContainer"+ (selectedTab===2?" showRequests":"")}>




        <div className="Friends">
            {props.friendData?.friends?.map?.((friend,index)=>{
                return(
                <div key={index} className="Friend">
                    <div className={friend.isonline===true?"isonline":""}></div>
                    <label>{friend.name}</label>
                    <div className="confirmationbtns btns">
                    <button className="removebtn" onClick={()=>SendFriendAction("remove", friend.userID)} >Remove</button>
                    <button className="messagebtn" onClick={(ev)=>{
                        ev.target?.parentElement?.parentElement?.classList?.remove("removeopen")
                    }} >Cancel</button>
                    </div>
                    <div className="defaultbtns btns">
                    <button onClick={()=>{
                        homeContext?.dispatch({type:"selectprofile", payload:friend.userID})
                    }} className="messagebtn">Profile</button>
                    <button onClick={(ev)=>{
                        ev.target?.parentElement?.parentElement?.classList?.add("removeopen")
                    }} className="removebtn">Remove</button>
                    </div>
                </div>
                )
            })

            }

        </div>

        <div className="Requests">
            <div className="Incoming">
                <div className="header"><label>Incoming</label></div>
                <div className="container">
                    {
                        props.friendData?.received?.map?.((request,index)=>{
                            
                            return(

                                <div key={index}>
                                     <label>{request.name}</label>
                                     <div onClick={async(ev)=>{
                                        ev.target?.classList?.add("loading")
                                        const status = await SendFriendAction("acceptfr", request.userID)
                                        ev.target?.classList?.remove("loading")
                                        }} ><img width={"16px"} height={"16px"} src={doneIcon}></img><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                                        
                                     <div onClick={async(ev)=>{
                                        ev.target?.classList?.add("loading")
                                        const status = await SendFriendAction("denyfr", request.userID)
                                        ev.target?.classList?.remove("loading")
                                    }} ><img width={"12px"} height={"12px"} src={closeIcon}></img><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                                </div>

                            )


                        })

                    }
                </div>

            </div>
            <div className="Outgoing">
                <div className="header"><label>Outgoing</label></div>
                <div className="container">
                    {
                            
                            props.friendData?.sent?.map?.((request,index)=>{
                    
                                return(

                                    <div key={index}>
                                        <label>{request.name}</label>
                                        <div  onClick={async(ev)=>{
                                            ev.target?.classList?.add("loading")
                                            const status = await SendFriendAction("cancelfr", request.userID)
                                            ev.target?.classList?.remove("loading")
                                        }} ><img width={"12px"} height={"12px"} src={closeIcon}></img><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                                            
                                    </div>

                                )


                            })

                        }

                </div>

            </div>

        </div>


    </div>



    </div>)
}