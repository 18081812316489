import React, { useEffect, useRef, useState } from "react"

import { useHomeContext } from "../Contexts/HomeContext";
import GridSpace from "./GridSpace"
import PlayingPiece from "./PlayingPiece"
import StaticPiece from "./StaticPiece";





import  PlusPiece, {defaultmatrix as PlusPieceMatrix} from "./PlusPiece";
import LPiece , {defaultmatrix as LPieceMatrix} from "./LPiece";
import SwordsmanPiece, {defaultmatrix as SwordsmanPieceMatrix} from "./SwordsmanPiece";
import HelmetPiece, {defaultmatrix as HelmetPieceMatrix} from "./HelmetPiece";
import LongboyPiece, {defaultmatrix as LongboyPieceMatrix} from "./LongboyPiece";
import OnerPiece, {defaultmatrix as OnerPieceMatrix} from "./OnerPiece";
import SPiece, {defaultmatrix as SPieceMatrix} from "./SPiece";
import StickPiece, {defaultmatrix as StickPieceMatrix} from "./StickPiece";
import ToePiece, {defaultmatrix as ToePieceMatrix} from "./ToePiece";
import WormPiece, {defaultmatrix as WormPieceMatrix} from "./WormPiece";
import ZombiePiece, {defaultmatrix as ZombiePieceMatrix} from "./ZombiePiece";
import TPiece, {defaultmatrix as TPieceMatrix} from "./TPiece";
import StairPiece, {defaultmatrix as StairPieceMatrix} from "./StairPiece";
import CarPiece, {defaultmatrix as CarPieceMatrix} from "./CarPiece";
import ArmPiece, {defaultmatrix as ArmPieceMatrix} from "./ArmPiece";
import AdoptedPiece, {defaultmatrix as AdoptedPieceMatrix} from "./AdoptedPiece";
import BalancePiece, {defaultmatrix as BalancePieceMatrix} from "./BalancePiece";
import BigLPiece, {defaultmatrix as BigLPieceMatrix} from "./BigLPiece";
import BatonPiece, {defaultmatrix as BatonPieceMatrix} from "./BatonPiece";
import FingerPiece, {defaultmatrix as FingerPieceMatrix} from "./FingerPiece";
import GlockPiece, {defaultmatrix as GlockPieceMatrix} from "./GlockPiece";
import SquarePiece, {defaultmatrix as SquarePieceMatrix} from "./SquarePiece";


import TurnIndicator from "./TurnIndicator";
import { useWebSocketContext } from "../Contexts/WebSocketContext";



















export default function Grid(props){
    const [adjustedMatrix, setAdjustedMatrix] = useState(null)
    const [gridInfo, setGridInfo] = useState(Array.from(Array(20)).map(()=>{
        return(Array.from(Array(20), ()=>-1))}))
    const [waitForTurn, setWaitForTurn]=useState(false)
    const [coordinates, setCoordinates] = useState([9, 9])
    const [placedPieces, setPlacedPieces] = useState([])
    const [showError, setShowError] = useState(false)
    const [moveRequest, setMoveRequest]=useState(200)

    
  


    
    const errorTimer = useRef(null)
    const prevEvent = useRef(null)


    const gameContext = useHomeContext()
    const webSocketContext = useWebSocketContext()

    function sendMoveRequest(piececoordinates, orientation, name){
        
      
        setMoveRequest(0)
       setTimeout(()=>{
        webSocketContext.dispatch({type:"placepiece", payload:{coordinates:piececoordinates, orientation:orientation, name:name} })
        },1000)
        
        //if fail set move request 404 and dont change selectedpiece or coords
    }


    function calculateAdustedMatrix(defaultMatrix, orientation){
        let workingarray;
        if(defaultMatrix){

            if(orientation.rotation===0||orientation.rotation===2){
                workingarray = Array.from(Array(defaultMatrix.length))
                const defaultcopy = defaultMatrix.map((row)=>{return([...row])})
                if (orientation.rotation===0){
                workingarray=defaultcopy
           
                  

                }else{
                defaultcopy.forEach((row)=>{
                    return row.reverse()
                })
                workingarray=defaultcopy

                workingarray.reverse()
            
                }


            }else{

                workingarray = Array.from(Array(defaultMatrix[0].length))
                



                workingarray = workingarray.map((val, ind)=>{
                    
                    const column = []
                    

                    const index = orientation.rotation===3?((defaultMatrix[0].length-1)-ind):ind

                

                    defaultMatrix.forEach(row=>{
                        
                        column.push(row[index])
                    })
                   
                    if(orientation.rotation===1){

                    
                        return column.reverse()
                        }else{
                            return column
                        }
                        
                    
                    

                })

            }
            



                if(orientation.flipped){
                    workingarray.forEach(row=>{
                        row.reverse()
                    })
                }


                return(workingarray)

            





        }


    }

    function serverPlacePiece(pieceinfo){

        const team = pieceinfo.corner
        const defaultMatrix = matrixMapping[pieceinfo.name]





        if(defaultMatrix){



            const adjustedMatrix = calculateAdustedMatrix(defaultMatrix, pieceinfo.orientation)

            setGridInfo(prev=>{
                const array = prev.map(row=>{
                    return([...row])
                })
                adjustedMatrix.forEach((row, num)=>{
                    const ycoord = pieceinfo.coordinates[1]-(adjustedMatrix.length-num-1)
                    row.forEach((x, n)=>{
                        if(x===null)return
                        const xcoord = pieceinfo.coordinates[0]+n
                        if(array?.[xcoord]?.[ycoord]===-1){
                            array[xcoord][ycoord]=team
                        
                        }
                    })      
                })
                return array
            })
            setPlacedPieces(prev=>{
            
            return([...prev,{coordinates:pieceinfo.coordinates, orientation:pieceinfo.orientation, name:pieceinfo.name, corner:team}])
        })







        }
        if(moveRequest===0){
            setMoveRequest(200)
            setSelectedPieceF()
            setCoordinates([9,9])
        }


        
    }





    const placePiece = (pieceinfo)=>{
            pieceinfo = pieceinfo||props.selectedPieceInfo
     
            
            const team = pieceinfo.corner
       
           
            if(team!==gameContext?.state?.playingfor){

              setShowError(true)
              clearTimeout(errorTimer)
              errorTimer.current=setTimeout(()=>{
                  setShowError(false)
              }, 500)
            
              return
            }


       


            const canplace = checkCanPlace(adjustedMatrix,null, team)
            if (canplace===1){ 
                            if(team!==gameContext.state.curcorner){
        
                if(!waitForTurn){

                    setWaitForTurn(true)
                    setTimeout(()=>setWaitForTurn(false), 2000)
                }
                return
            }
                
                
                sendMoveRequest(coordinates,props.selectedPieceInfo?.orientation, props.selectedPieceInfo?.name,props.selectedPieceInfo?.corner)


                

              
            }else{
 
                setShowError(true)
                clearTimeout(errorTimer)
                errorTimer.current=setTimeout(()=>{
                    setShowError(false)
                }, 500)
            }

    }



    useEffect(()=>{
      
        if(props.replay){

            setPlacedPieces(props.replay.placedPieces)
            gameContext?.dispatch({type:"setreplaycorners", payload:props.replay.corners})
        }

       

    }, [props.replay])




    useEffect(()=>{
        attemptHover(adjustedMatrix, coordinates)

       

    }, [adjustedMatrix])




    useEffect(()=>{
        const event = gameContext?.state?.passevent
        if(prevEvent.current!=null){
                
        
        if(event){
  

 
            

            if(prevEvent.current!==event.id){
                
                        if(prevEvent.current==null){
                            prevEvent.current = event.id
                            return
                        }

       
    
            const data = event.payload
   
            
            switch(event.type){
                case "placepiece":{
        
                    serverPlacePiece(data)
                    break
                }
                case "waitforturn":{
                 
                    if(!waitForTurn){

                        setWaitForTurn(true)
                        setTimeout(()=>setWaitForTurn(false), 2000)
                    }
                    setMoveRequest(200)

                    break

                }
                case "cannotplace":{
                 
                   
                    setShowError(true)
                    clearTimeout(errorTimer)
                    errorTimer.current=setTimeout(()=>{
                        setShowError(false)
                    }, 500)
                    setMoveRequest(200)

                    break

                }
                default:{
                    break
                }

            }
            prevEvent.current=event.id
        }
            


        
        }
    }else{
        prevEvent.current = 0

    }

       

    }, [gameContext?.state?.passevent])




    function decodePiece(corner,name,encodedinfo){
        return({name: name,orientation:{flipped:Boolean(Number(encodedinfo.slice(0,1))), rotation:Number(encodedinfo.slice(1,2))}, coordinates:[Number(encodedinfo.slice(2,4)), Number(encodedinfo.slice(4,6))], corner:corner})
    }

    useEffect(()=>{
        
    if(gameContext?.state?.stage==="gamestarted"){
        setPlacedPieces(()=>{
            const allpieces = []
           const piecesarray  = gameContext?.state?.corners?.map?.(corner=>corner.pieces)
           
           
           piecesarray.forEach((pieces,index)=>{
                Object.keys(pieces).forEach(piecekey=>{
                    if(piecesarray[index][piecekey]===0)return
                    const encodedinfo = piecesarray[index][piecekey]
        
                    const pieceinfo = decodePiece(index, piecekey, encodedinfo)
                    serverPlacePiece(pieceinfo)
                    allpieces.push(pieceinfo)

                })



           })
           
           



           return(allpieces)
        
        
        
        
        })

    }
    }, [gameContext?.state?.stage==="gamestarted"])


    const gridref = useRef(null)
    


    function checkCanPlace(pieceMatrix, pieceLocation, corner){
        if (!pieceLocation)pieceLocation=coordinates
        if(!pieceMatrix)return
        
        
        const pieceAudit = {outofbounds:false, overlaps:false,touchescorner1:false, touchescorner2:false, touchescorner3:false, touchescorner4:false, touching:false,connected:false}

        pieceMatrix.forEach((row,num)=>{
          
            row.forEach((x, n) =>{
              
                
                if(x!==1)return
                

                const [absX, absY] = [pieceLocation[0]+n, pieceLocation[1]-pieceMatrix.length+1+num]
            
                if (!(-1 < absX && absX<20) && !(-1 < absY && absY<20)){pieceAudit.outofbounds=true
                    
                return }
                if(-1!==gridInfo[absX][absY]){pieceAudit.overlaps=true
                    return}
                   
                if (absX===0 && absY ===0)pieceAudit.touchescorner1=true
               
                if (absX===19 && absY ===0)pieceAudit.touchescorner2=true
                if(absX===0 && absY ===19)pieceAudit.touchescorner4=true
                if (absX===19 && absY ===19)pieceAudit.touchescorner3=true
                


                if(absX+1<20){
                   if(gridInfo[absX+1][absY]===corner){
                    pieceAudit.touching=true
                    return
                   }


                }
                if(absX-1>-1){
                    if(gridInfo[absX-1][absY]===corner){
                        pieceAudit.touching=true
                        return
                       }
    
                }
                if(absY+1<20){
                    if(gridInfo[absX][absY+1]===corner){
                        pieceAudit.touching=true
                        return
                       }
    
                }
                if(absY-1>-1){
                    if(gridInfo[absX][absY-1]===corner){
                        pieceAudit.touching=true
                        return
                       }
    
                }


               


                    
                if(absX-1 > -1 && absY -1 > -1){
                 
                    if(gridInfo[absX-1][absY-1]===corner)pieceAudit.connected=true
                       
                    

                }
                if(absX+1 < 20 && absY -1 > -1){
           
                    if(gridInfo[absX+1][absY-1]===corner)pieceAudit.connected=true
                }
                if(absX -1 >-1 && absY+1 < 20){
                  
                    if(gridInfo[absX-1][absY+1]===corner)pieceAudit.connected=true
                }
                if(absX+1 < 20  && absY+1 < 20){
              
                    if(gridInfo[absX+1][absY+1]===corner)pieceAudit.connected=true
                }

              

                
                


                
               
        
            } )
            
            });

            
            if((corner===3&&pieceAudit.touchescorner4===true)||(corner===2&&pieceAudit.touchescorner3===true)||(corner===1&&pieceAudit.touchescorner2===true)||(corner===0&&pieceAudit.touchescorner1===true))pieceAudit.connected=true
  
            return((pieceAudit.outofbounds ? -1:0) || (pieceAudit.overlaps ? -2:0) ||(pieceAudit.touching ? -3:0)  || (pieceAudit.connected===false ? -4:0) ||1)
          
    }

    const movepiece = (direction) =>{
        if(direction==="up"){
          
           
            setCoordinates(prev=>{
           
                if(attemptHover(adjustedMatrix, [prev[0],prev[1]-1])===true){
                    return([prev[0],prev[1]-1])
                }else{
                    return(prev)
                }
              

            })
        }
        if(direction==="down"){
            
            setCoordinates(prev=>{
             
                if(attemptHover(adjustedMatrix, [prev[0],prev[1]+1])===true){
                    return([prev[0],prev[1]+1])
                }else{
                    return(prev)
                }
              

            })




        }
        if(direction==="left"){


            setCoordinates(prev=>{
             
                if(attemptHover(adjustedMatrix, [prev[0]+1,prev[1]])===true){
                    return([prev[0]+1,prev[1]])
                }else{
                    return(prev)
                }
              

            })

        }

        if(direction==="right"){


            setCoordinates(prev=>{
             
                if(attemptHover(adjustedMatrix, [prev[0]-1,prev[1]])===true){
                    return([prev[0]-1,prev[1]])
                }else{
                    return(prev)
                }
              

            })


        }


    }

   /* const gridhovered = (coordinates) =>{

        setCoordinates(coordinates)
        

    }*/

    const componentMapping = {
    "X": PlusPiece,
    "V3" :LPiece,
    "N":  SwordsmanPiece,
    "U":   HelmetPiece,
    "I5":  LongboyPiece,
    "1": OnerPiece,
    "Z4": SPiece,
    "I4": StickPiece ,
    "2": ToePiece ,
    "Z5" : WormPiece,
    "T4": ZombiePiece,
    "T5": TPiece ,
    "W": StairPiece,
    "P": CarPiece,
    "L5": ArmPiece,
    "F": AdoptedPiece,
    "A" : BalancePiece,
    "V5": BigLPiece,
    "Y": BatonPiece,
    "I3":FingerPiece,
    "L4":  GlockPiece,
   "O":SquarePiece



}

const matrixMapping = {
    "X": PlusPieceMatrix,
    "V3": LPieceMatrix,
    "N":  SwordsmanPieceMatrix,
    "U":   HelmetPieceMatrix,
    "I5":  LongboyPieceMatrix,
    "1": OnerPieceMatrix,
    "Z4": SPieceMatrix,
    "I4": StickPieceMatrix,
    "2": ToePieceMatrix,
    "Z5" : WormPieceMatrix,
    "T4": ZombiePieceMatrix,
    "T5": TPieceMatrix,
    "W": StairPieceMatrix,
    "P": CarPieceMatrix,
    "L5": ArmPieceMatrix,
    "F": AdoptedPieceMatrix,
    "A" : BalancePieceMatrix,
    "V5": BigLPieceMatrix,
    "Y": BatonPieceMatrix,
    "I3":FingerPieceMatrix,
    "L4":  GlockPieceMatrix,
   "O":SquarePieceMatrix



}



    function setSelectedPieceF(pieceName, pieceOrientation, corner){
    props.setSelectedPieceInfo({name:pieceName, orientation:pieceOrientation, corner:corner})
   }

    function attemptHover(matrix, coords, isSelected){

        /*if(hoveredSquare!=null&&coords === "recalc"){coords = hoveredSquare
        setCoordinates(coords)


      
        }*/
       
  

        if(matrix?.[0]?.length==null||!coords)return
        if(coords[0]<0 || coords[1]>19)return
        const overX = matrix[0].length+coords[0]>20
        const overY = coords[1]-matrix.length <-1
  

        if (overX && overY){

            setCoordinates([20-matrix[0].length,-1+matrix.length, true])
            return("overXY")
        }else if (overX){
            setCoordinates(prev=>{
                const y = prev[1]-matrix.length<-1?-1+matrix.length:prev[1]
                

                return([20-matrix[0].length,y, true])})
            return("overX")
        }else if(overY){
            setCoordinates(prev=>{
                const x = prev[0]+matrix[0].length>20?20-matrix[0].length:prev[0]
               return( [x,-1+matrix.length, true])})
            return("overY")
        }





        return true
        

    }



    return(<div ref={gridref} className={"Grid"+(showError===true?" error":"")+(moveRequest===0?" loading":"")+(waitForTurn===true?" waitforturn":"")}>

        
        {
            Array.from(Array(20)).map((nothing,index)=>{
                return(
                <div className="GridRow" key={index}>
               { Array.from(Array(20)).map((nothing,ind)=>{
                    return(<GridSpace key={ind}/>)

                })}
                </div>
            )})
        }

        {
            placedPieces?.map?.((pieceinfo ,key)=>{
                return(<StaticPiece key={key} coordinates={pieceinfo.coordinates} pieceOrientation={pieceinfo.orientation} pieceCorner={pieceinfo.corner} >

                    {React.createElement(componentMapping[pieceinfo.name])}
                </StaticPiece>)
            })
        }

        {
        
        props.selectedPieceInfo?.name!=null? <PlayingPiece rotateFlip={props.rotateFlip} changepiece={props.changepiece} placePiece={placePiece} pieceCorner={gameContext?.state?.playingfor} movepiece={movepiece} notStatic={true} coordinates={coordinates} attemptHover={attemptHover} setAdjustedMatrix={setAdjustedMatrix} orientation={props.selectedPieceInfo?.orientation} setPieceSelected={setSelectedPieceF}>
            {React.createElement(componentMapping[props.selectedPieceInfo?.name])}
        </PlayingPiece>:""

     

        

        }
    <TurnIndicator color={gameContext?.state?.corners?.[0]?.color} team={gameContext?.state?.corners?.[0]?.team} currentturn={gameContext.state?.curcorner} corner={"1"}/>
    <TurnIndicator color={gameContext?.state?.corners?.[1]?.color} team={gameContext?.state?.corners?.[1]?.team}  currentturn={gameContext.state?.curcorner} corner={"2"}/>
    <TurnIndicator color={gameContext?.state?.corners?.[2]?.color} team={gameContext?.state?.corners?.[2]?.team} currentturn={gameContext.state?.curcorner}corner={"3"}/>
    <TurnIndicator color={gameContext?.state?.corners?.[3]?.color} team={gameContext?.state?.corners?.[3]?.team} currentturn={gameContext.state?.curcorner} corner={"4"}/>

    </div>)
}