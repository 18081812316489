import React, {useEffect} from "react"
const defaultmatrix = [[1, null, null,null], [1,1,1, 1]  ]
export default function ArmPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("L5")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}
export {defaultmatrix}