import React, {useState, useEffect, useRef} from "react"
import { useHomeContext } from "../Contexts/HomeContext";
import Block from "./Block"
import EmptySpace from "./EmptySpace"
import { dragndropIcon } from "../Helpers/Icons";


export default function PlayingPiece(props){

    const [defaultMatrix, setDefaultMatrix] = useState([]) 
    const [adjustedMatrix, setAdjustedMatrix] = useState([])
    const [pieceName, setPieceName] = useState(null)
    const [pieceOrientation, setPieceOrientation]=useState({rotation:0, flipped:false})
    const [rotateFlip, setRotateFlip] = useState(null)
    const dragging = useRef(null)


    const  gameContext = useHomeContext()

    const pieceRef = useRef(null)


    const pointerp = useRef(null)
    const setpointerp = data => {
        pointerp.current = data 
    
    }


    function placepiece(){
        props.placePiece?.()
    }
    function dragmove(event){
        event.preventDefault()
        if(!dragging||!pointerp)return
        const xdif = pointerp.current[0]-event.clientX
        const ydif = pointerp.current[1]-event.clientY
        if(xdif>30){
       
            setpointerp([event.clientX, pointerp.current[1]])
            props.movepiece("right")

        }else if (xdif<-30){
            setpointerp([event.clientX, pointerp.current[1]])
            props.movepiece("left")
        }
        if(ydif>30){
            setpointerp([pointerp.current[0], event.clientY])
            props.movepiece("up")

        }else if (ydif<-30){
            setpointerp([pointerp.current[0], event.clientY])
            props.movepiece("down")
        }
        


    }

    function dragstart(event){
        
            
            dragging.current=true
            setpointerp([event.clientX, event.clientY])
            event.target.setPointerCapture(event.pointerId)
            
            event.target.onpointermove=dragmove
            event.target.onpointerup=function(){
                event.target.onpointermove=null
            dragging.current=false
                event.target.onpointerup=null
            }

        
            
        

    }

  


    useEffect(()=>{
        setAdjustedMatrix(defaultMatrix)

    }, [defaultMatrix])




  
    useEffect(()=>{
        if(props.rotateFlip!==0){
          setRotateFlip(props.rotateFlip)}
      }, [props.rotateFlip])

    useEffect(()=>{
        if(props.defaultpiece){
            setPieceSelected()
        }
    },[pieceName])

    useEffect(()=>{
        if(props.selectedPiece?.name===pieceName){

      

           setRotateFlip(props.rotateFlip)


        }





    }, [props.rotateFlip])

    useEffect(()=>{
        if (rotateFlip===-1){
            setPieceOrientation(prev=>{return({...prev, flipped:!prev.flipped})})

            

        }else if (rotateFlip===1){


            


            setPieceOrientation(prev=>{

                let num
                let trynum
                if (pieceOrientation.flipped){
                    trynum = prev.rotation-1
                }else{
                    trynum = prev.rotation+1
                }

                if(trynum>3){
                    num = 0
                }else if(trynum<0){
                    num=3
                }else{
                    num=trynum
                }

                
             
          
                return({...prev, rotation:num})})

        }
        setRotateFlip(0)
    }, [rotateFlip])



    useEffect(()=>{
       
        if(props.coordinates){

            if(props.coordinates[2]!==true){
 
            
            const hoverSuccess = props.attemptHover(adjustedMatrix, props.coordinates, true)
           
            if(hoverSuccess==="overXY")return
            if(hoverSuccess!=="overY"){
                pieceRef.current.style.bottom = ((18-props.coordinates[1])*32)+12+"px"
            }
           
            if(hoverSuccess!=="overX"){
            pieceRef.current.style.left = (props.coordinates[0]*32)+"px"
            }
          
        }else{
  
            pieceRef.current.style.left = (props.coordinates[0]*32)+"px"
            pieceRef.current.style.bottom = ((18-props.coordinates[1])*32)+12+"px"
        }}

    }, [props.coordinates])


    useEffect(()=>{
        let workingarray;
        if(defaultMatrix){

            if(pieceOrientation.rotation===0||pieceOrientation.rotation===2){
                workingarray = Array.from(Array(defaultMatrix.length))
                const defaultcopy = defaultMatrix.map((row)=>{return([...row])})
                if (pieceOrientation.rotation===0){
                workingarray=defaultcopy
           
                  

                }else{
                defaultcopy.forEach((row)=>{
                    return row.reverse()
                })
                workingarray=defaultcopy

                workingarray.reverse()
            
                }


            }else{

                workingarray = Array.from(Array(defaultMatrix[0].length))
                



                workingarray = workingarray.map((val, ind)=>{
                    
                    const column = []
                    

                    const index = pieceOrientation.rotation===3?((defaultMatrix[0].length-1)-ind):ind

                

                    defaultMatrix.forEach(row=>{
                        
                        column.push(row[index])
                    })
                   
                    if(pieceOrientation.rotation===1){

                    
                        return column.reverse()
                        }else{
                            return column
                        }
                        
                    
                    

                })

            }
            



                if(pieceOrientation.flipped){
                    workingarray.forEach(row=>{
                        row.reverse()
                    })
                }


                setAdjustedMatrix(workingarray)

            





        }

        if(pieceName!=null){
        props.setPieceSelected(pieceName,pieceOrientation,(props.pieceCorner||props.selectedPieceInfo?.corner||gameContext.state.playingfor))
        }
        


    }, [pieceOrientation])

    useEffect(()=>{
        
        if(props.orientation){
            setPieceOrientation(props.orientation)

      
        
       
         
        }

     
    
    }, [props.orientation])

    useEffect(()=>{
    

        if (props.setAdjustedMatrix != null){
            props.setAdjustedMatrix(adjustedMatrix)
        }


    }, [adjustedMatrix])

    function setPieceSelected(){
        if(!pieceRef)return
        props.setPieceSelected(pieceName,pieceOrientation,(props.pieceCorner||props.selectedPieceInfo?.corner||0))

       
        
    }


    return(
        <div onClick={setPieceSelected} className={(props.handpiece===true?"hand":"board")+"piece"+(props.selectedPiece?.name===pieceName?" selected":"")}>
        <div ref={pieceRef} className={"PlayingPiece"+(props.notStatic?" onboard":"")}>

           {React.cloneElement(props.children, {setDefaultMatrix:setDefaultMatrix, setName:setPieceName})}
           
                <div className="blocks">
           {adjustedMatrix?.map?.((row,ind)=>{
                
                  
                    let index = 0
                    return(
                    <div className="row" key={ind}>

                    {row?.map?.(column=>{
                       
                        if(column==null){
                            
                            return(<EmptySpace key={index++}/>)
                        }else{
                            
                            return(<Block color={gameContext?.state?.corners?.[props.pieceCorner]?.color} key={index++}/>)
                        }
                        
                    })}
                    </div>

                    )
                
            })}
            </div>

{props.notStatic?<div className="piecemenu">
           
            <div className="orientation">
            <label>Wait for turn.</label>
            <button onClick={

(event)=>{
    
    setRotateFlip(-1)
    event.target.classList.add("clicked")
    setTimeout(()=>{
        event.target.classList.remove("clicked")
    },250)
    
    

}
}  className="pmflip"/>
            <div className="dragbar" onPointerDown={dragstart} ><img draggable={"false"} alt="Drag"  src={dragndropIcon} height={"10px"} width={"10px"}/></div>
            
            <button onClick={

                (event)=>{

                    setRotateFlip(1)
                    event.target.classList.add("clicked")
                    setTimeout(()=>{
                        event.target.classList.remove("clicked")
                    },250)
                    
                    

                }
            } className="pmrotate"/>
            </div>


            <div className="pieceselection">
                <button  onClick={()=>{props.changepiece?.(-1) }} className="prev"/>
                <label>{pieceName}</label>
                <button onClick={()=>props.changepiece?.(1)} className="next"/>

            </div>
            

            <svg onClick={(event)=>{
                event.stopPropagation()
                placepiece()}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="placepiecebtn" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
        </div>:""}
        </div>



  
        </div>

    )

}

