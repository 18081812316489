import React, {useEffect} from "react"
const defaultmatrix = [[null,1,null, null] ,[1, 1, 1,1]]
export default function BatonPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("Y")
        
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}
export {defaultmatrix}