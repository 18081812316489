const accesssettings =   {
    authorizationParams:{
        audience:"development",
        redirect_uri:window.location.origin,
        cacheMode:"on"
        
    },
    
     timeoutInSeconds:5
}

export default accesssettings