export default function TurnIndicator(props){



    return(<div className={"TurnIndicator Color"+props.color+(" corner"+props.corner)+(props.currentturn+1===props.corner?" isturn":"")+(props.team!=null?(props.team===0?" Team1":" Team2"):"")}> 

    
    
    
    </div>)
}