import React, {useEffect} from "react"
const defaultmatrix = [[1, 1, 1] ]
export default function FingerPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("I3")
        
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export {defaultmatrix}