import React, {useState, useEffect} from "react";
import { useHomeContext } from "../Contexts/HomeContext";
import Grid from "./Grid";
import Hand from "./Hand";
import PostGame from "./PostGame";






export default function Game(props){
    const [selectedPieceInfo, setSelectedPieceInfo] = useState({name:"1",orientation:{rotation:0, flipped:false}, corner:0})
    const [rotateFlip, setRotateFlip] = useState(0)
    const gameContext = useHomeContext()
    const [pieceChange, setPieceChange] = useState(null)


    useEffect(()=>{
      setPieceChange(null)

    }, [pieceChange])

    useEffect(()=>{
      setRotateFlip(0)

    }, [rotateFlip])



    function placePiece(){

    }
    function changePiece(dir){
      setPieceChange(dir)
    }

    function keyDown(event){

      if (event.repeat) return
       
  


      if(event.code==="KeyR"){
  
        setRotateFlip(prev=>{
          if(prev===0)return(1)
        })
       

      }else if(event.code==="KeyE"){

        setRotateFlip(prev=>{if(prev===0)return(-1)})
     
      }
    }

    
    return(
    
      <React.Fragment>
  
    <div tabIndex={0} onKeyDown={keyDown} className="GameDiv">



            <div  className="GameBoard">
          
            <Grid rotateFlip={rotateFlip} replay={props.replay} selectedEvent={props.selectedEvent} changepiece={(props.replay?null:changePiece)} setSelectedPieceInfo={(props.replay?null:setSelectedPieceInfo)} selectedPieceInfo={(props.replay?null:selectedPieceInfo)} placePiece={(props.replay?null:placePiece)}/>

            </div>
          
          {props?.replay!=null?null:<Hand pieceChange={pieceChange} rotateFlip={rotateFlip} selectedPieceInfo={selectedPieceInfo} setSelectedPieceInfo={setSelectedPieceInfo}/>}

          
    </div>

    {gameContext?.state?.enddata!=null?<PostGame game={gameContext?.state?.enddata}/>:null}

    </React.Fragment>
)
}