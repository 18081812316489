import React, {useEffect} from "react"
const defaultmatrix = [[1, null, null], [1, null, null],[1, 1, 1] ]
export default function BigLPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("V5")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export {defaultmatrix}