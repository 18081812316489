import React, {useEffect} from "react"
const defaultmatrix = [[1, 1, null], [null, 1, 1],[null, 1, null] ]
export default function AdoptedPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("F")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export{ defaultmatrix }