import React, {useEffect} from "react"
const defaultmatrix = [[null, 1,1], [1, null,1], [null, 1,null]]
export default function BalancePiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("A")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export {defaultmatrix}