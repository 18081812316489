import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import Match from "./Match"
import { protocol,endpoint } from "../Config/Endpoint"
import { useAuth0 } from "@auth0/auth0-react"
import { useHomeContext } from "../Contexts/HomeContext"
import accesssettings from "../Config/AccessSettings"

export default function Profile(props){
    const homeContext = useHomeContext()
    const [user,setUser]=useState(null)
    const [showSendFr, setShowSendFr] = useState(true)
    const {getAccessTokenSilently, user:isSignedIn} = useAuth0()
    //const [matches,setMatches]=useState(null)


    async function sendFriendRequest(ev){

        if(user){
        if(ev.target)ev.target?.classList?.add("loading")

 try{

    const token = await getAccessTokenSilently()

    const l = await fetch(protocol+endpoint+"/user/sendfriendrequest/"+user?.userID, { headers:{ Authorization: `Bearer ${token}`,
        "Content-Type":"application/json",   
    }, method:"POST",  credentials:"include"})


        if(l.status!==500){
            setShowSendFr(false)
        }

        }catch(error){
            console.log(error)
            
        }finally{
            if(ev.target)ev.target?.classList?.remove("loading")
        }


        }
    }

    async function getUserInfo(userid){
        try{
            const data = await fetch(protocol+endpoint+"/users/"+userid,  {
            "Content-Type":"application/json",   
         method:"GET", credentials:"include"})
            const userinfo = await data.json()

            setUser(userinfo)


        }catch(error){





        }




    }

    useEffect(()=>{
        if(props.userid!=null){
            getUserInfo(props.userid)

        }
    }, [props.userid])


    

    return(<div className="ProfileDiv">
    <div className="Head">
        {user!=null?<label>{user?.name||"User "+props.userid}</label>:null}
        { (isSignedIn&&showSendFr&&user?.name && props.userid!=homeContext?.state?.persistedData?.localuserdata?.userID && !homeContext?.state?.persistedData?.localuserdata?.friends?.find(friend=>friend.userID!=props.userID) && !homeContext?.state?.persistedData?.localuserdata?.sentRequests?.find(req=>props.userID===req.userid))?<div onClick={sendFriendRequest}><div className="loaderdiv"><div></div><div></div><div></div>
<div></div></div><label>Send friend request</label></div>:null}
    </div>


    <div className="StatsDiv">
        <label>Stats</label>


        <div >
            <div className="StandardDiv">

                {user!=null?<div><label>Standard</label></div>:<div></div>}
                <div>
                    
                    {


                    user!=null?
                    <>
                    <label>{user?.stats?.standard?.played??0}</label>
                    <div>
                        <div className="WLDiv">
                            <label><span>W</span>/L</label>
                            <label><span>{(user.stats?.standard?.won??0)??0}</span>{"/"+((user?.stats?.standard?.played-(user?.stats?.standard?.won??0))||0)}</label>
                        </div>
                        <div className="WRDiv">

                            <label>%</label>
                            <label>{Math.ceil((user?.stats?.standard?.won??0)/user?.stats?.standard?.played*100)??"N/A"}</label>
                        </div>
                        <div className="RatingDiv">
                            <label>Rating</label>
                            <label>{user?.stats?.standard?.rating??"1000"}</label>
                        </div>
                    </div> </>:<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />
                    }
                </div>
            </div>


            <div>

                {user!=null?<div><label>Duel</label></div>:<div></div>}
                <div>

                    {

                    user!=null?<>
                    <label>{user?.stats?.duel?.played??0}</label>
                    <div>
                    <div className="WLDiv">
                            <label><span>W</span>/L</label>
                            <label><span>{(user?.stats?.duel?.won??0)??0}</span>{"/"+((user?.stats?.duel?.played-(user?.stats?.duel?.won??0))||0)}</label>
                        </div>
                        <div className="WRDiv">

                            <label>%</label>
                            <label>{Math.ceil((user?.stats?.duel?.won??0)/user?.stats?.duel?.played*100)??"N/A"}</label>
                        </div>
                    </div>
                        </>:<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />
                        }
                </div>
            </div>

  

                <div>
                {user!=null?<div><label>Threes</label></div>:<div></div>}
                <div>
                {

                user!=null?<>
                <label>{user?.stats?.threes?.played??0}</label>
                    <div>
                    <div className="WLDiv">
                            <label><span>W</span>/L</label>
                            <label><span>{(user?.stats?.threes?.won??0)??0}</span>{"/"+((user?.stats?.threes?.played-(user?.stats?.threes?.won??0))||0)}</label>
                        </div>
                        <div className="WRDiv">

                            <label>%</label>
                            <label>{Math.ceil((user?.stats?.threes?.won??0)/user?.stats?.threes?.played*100)??"N/A"}</label>
                        </div>
                    </div>
                    </>
                    :<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />
                }
                </div>


                </div>
      

                
            <div>
                 {user!=null?<div><label>Teams</label></div>:<div></div>}
                <div>
                {
                user!=null?
                <>
                <label>{user?.stats?.teams?.played??0}</label>
                    <div>
                    <div className="WLDiv">
                            <label><span>W</span>/L</label>
                            <label><span>{(user?.stats?.teams?.won??0)??0}</span>{"/"+((user?.stats?.teams?.played-(user?.stats?.teams?.won??0))||0)}</label>
                        </div>
                        <div className="WRDiv">

                            <label>%</label>
                            <label>{Math.ceil((user?.stats?.teams?.won??0)/user?.stats?.teams?.played*100)??"N/A"}</label>
                        </div>
                    </div>
                     </>
                     :<Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} />
                }
                </div>
            </div>
        </div>


    </div>

    <div className="MatchHistoryDiv">
        <div className="Nav"><label>Match History</label></div>
        <div className="MatchesScroll">
        {user==null?<div className="MatchesLoading"> <Skeleton baseColor="rgb(50,50,50)"  height="100%" highlightColor="#363636" enableAnimation={true} /></div>:<div className="Matches">
                
               
               { user?.matches?.slice().reverse?.().map((matchinfo, index)=>{


                    return(<Match key={index} matchid={matchinfo.id} userid={props.userid}/>)

                })}

                




        </div>}
        </div>
    </div>


    </div>)
}