import React, {useEffect} from "react"
const defaultmatrix = [[1,1,1,1,1]]
export default function LongboyPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("I5")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export{defaultmatrix}