import React, {useEffect} from "react"
const defaultmatrix = [ [1, 1, 1,1] ]
export default function StickPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("I4")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}
export{defaultmatrix}