import React from 'react';
import ReactDOM from 'react-dom/client';
import {Auth0Provider} from "@auth0/auth0-react"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {disableReactDevTools} from "@fvilers/disable-react-devtools"
 const auth0config = {clientId:"KgN6yv8JEaCd5ZdID0awkOmvGy0OVCST", domain:"dev-4ck7aoetaeujhql8.us.auth0.com"}

if(process.env.NODE_ENV==="production"){
  disableReactDevTools()
  auth0config.domain="blokio.us.auth0.com"
  auth0config.clientId="dO8NjTMtgrVQ0v3QET2wY9hQuK2ow3Ou"

}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Auth0Provider
    
    domain={auth0config.domain}
    clientId={auth0config.clientId}
    scope="openid profile email offline_access"
    authorizationParams={{redirect_uri:window.location.origin,
      audience:"development"
    
    }}
    useRefreshTokens={true}
    useRefreshTokensFallback={true}
    cacheLocation= {'localstorage'}
    
  >

    <App />
    </Auth0Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
