
import { useHomeContext } from "../Contexts/HomeContext"
import { useWebSocketContext } from "../Contexts/WebSocketContext"
import { useAuth0 } from "@auth0/auth0-react"
export default function NavBar(props){
    const webSocketContext = useWebSocketContext()
    const homeContext = useHomeContext()

    


    return(
        <nav className="NavBar">
            <div className="Logo" onClick={()=>{
                webSocketContext?.dispatch({type:"leavegame"})}}>
           
           
           { (homeContext.state.stage!=="gamestarted"? <svg viewBox={"0 0 400 400"} width={"20px"} height={"20px"}>
                <path  d="M200,400L0,400L0,200L30,170L30,370L170,370L200,400Z" style={{fill:"rgb(222,29,0)"}}/>
                <path  d="M400,400L200,400L170,370L370,370L370,230L400,200L400,400Z" style={{fill:"rgb(52,0,222)"}}/>
                <path d="M400,0L400,200L370,230L370,30L230,30L200,0L400,0Z" style={{fill:"rgb(0,170,12)"}}/>
                <path d="M30,170L0,200L0,0L200,0L230,30L30,30L30,170Z" style={{fill:"rgb(255,214,0)"}}/>
                <path d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{fill:"rgb(255,214,0)", fillOpacity:".47"}}/>
                <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,-3.55271e-15,400)">
                    <path d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{fill:"rgb(222,29,0)",fillOpacity:"0.47"}}/>
                </g>
                <g transform="matrix(-1.83697e-16,1,-1,-1.83697e-16,400,3.19744e-14)">
                    <path d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{fill:"rgb(0,170,12)",fillOpacity:"0.47"}}/>
                </g>
                <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,400,400)">
                    <path d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{fill:"rgb(52,0,222)",fillOpacity:"0.47"}}/>
                </g>
            </svg>:

            <svg viewBox={"0 0 400 400"} width={"20px"} height={"20px"}>
            <path className={"Color"+homeContext?.state?.corners?.[3]?.color} d="M200,400L0,400L0,200L30,170L30,370L170,370L200,400Z" style={{fill:"rgb(222,29,0)"}}/>
            <path className={"Color"+homeContext?.state?.corners?.[2]?.color}  d="M400,400L200,400L170,370L370,370L370,230L400,200L400,400Z" />
            <path className={"Color"+homeContext?.state?.corners?.[1]?.color}  d="M400,0L400,200L370,230L370,30L230,30L200,0L400,0Z" />
            <path className={"Color"+homeContext?.state?.corners?.[0]?.color} d="M30,170L0,200L0,0L200,0L230,30L30,30L30,170Z" />
            <path className= {"Color"+homeContext?.state?.corners?.[0]?.color} d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{ fillOpacity:".47"}}/>
            <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,-3.55271e-15,400)">
                <path className={"Color"+homeContext?.state?.corners?.[3]?.color}   d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{fillOpacity:"0.47"}}/>
            </g>
            <g transform="matrix(-1.83697e-16,1,-1,-1.83697e-16,400,3.19744e-14)">
                <path className={"Color"+homeContext?.state?.corners?.[1]?.color}   d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{fillOpacity:"0.47"}}/>
            </g>
            <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,400,400)">
                <path  className={"Color"+homeContext?.state?.corners?.[2]?.color}  d="M230,30L245,45L45,45L45,155L30,170L30,30L230,30Z" style={{fillOpacity:"0.47"}}/>
            </g>
            </svg>
            
            
            )}
            <label>Blokio</label>

            </div>
        </nav>
    )
}