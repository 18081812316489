import React, {useEffect} from "react"
const defaultmatrix = [[1, 1,] ]
export default function ToePiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("2")
        
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}
export{defaultmatrix}