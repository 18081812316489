import { useEffect, useState } from "react"
import { protocol, endpoint } from "../Config/Endpoint"
import { useHomeContext } from "../Contexts/HomeContext"
import Game from "./Game"
import ReplayDock from "./ReplayDock"
export default function Replay(props){
    const homeContext = useHomeContext()
    const [replay, setReplay]=useState(null)
    const [boardState, setBoardState]=useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selectedEvent, setSelectedEvent]=useState(null)
  

    function setupReplay(match){

        const tempgame = {...match, eventlog:parseEventLog(match.eventlog)}

        const game = {...tempgame, corners:getCorners(tempgame)}

        return game


    }


    function parseEventLog(log){
        const events = []
        const unparsedevents = log.split("|")
        unparsedevents.forEach(ev=>{
            const arr = ev.split("/")
            const command = arr[0]
            const event = {}
            switch(command){

                case "START":{
                    event.type="Start"

                break
                }
                case "TE":{
                    event.type="Pass"
                    event.ply=Number(arr[1])
                    event.corner=Number(arr[2])
                break 
                }
                case "SC":{
                    event.type="Done"
                    event.corner=Number(arr[1])
                break  
                }
                case "P":{
                    event.type="placepiece"
                    event.ply=Number(arr[1])
                    event.corner=Number(arr[2])
                    event.name=arr[3]
                    const coords = arr[4].split?.(",")?.slice?.(0,2)?.map(string=>Number(string))
                    event.coordinates=coords
                    const orientation=arr[5].split?.(",")
                    event.orientation={flipped:Boolean(+orientation[0]), rotation:Number(orientation[1])}
                break
                }
                case "PS":{
                    event.type="FF"
                    event.ply=Number(arr[1])
                    break
                }
                case "END":{
                    event.type="End"

                break
                }
            }
            events.push(event)
        })
        return events
    }



    async function getGame(matchid){

        try{

       
        const res = await fetch(protocol+endpoint+"/replays/"+matchid,  {
            "Content-Type":"application/json",   
         method:"GET", credentials:"include"})
         const json = await res.json()
         
         if(json==null)throw new Error("Replay not found")

         setReplay(setupReplay(json))





        }catch(error){

            homeContext?.dispatch?.({type:"replaynotfound"})
        


        }finally{



        }



    }


    useEffect(()=>{
        if(props.matchid)getGame(props.matchid)

    }, [props.matchid] )



    useEffect(()=>{
        if(replay!=null){
        

            setTimeout(()=>setIsLoading(false),1000)
        }else{
            setIsLoading(true)
        }



    }, [replay] )

    




    function getCorners(replay){
        if(replay){
            const corners=[]
            replay?.players?.forEach(ply=>{
                const corner1event = replay.eventlog?.find(event=>event.ply===ply.plyid)
                const corner1=corner1event.corner
                let corner2

       
                if(ply.color2!=null){
                  
                    corner2=replay.eventlog?.find(event=>(event.ply===ply.plyid&&event.corner!==corner1event.corner))?.corner
                }

               const cornerobj1={corner:corner1, plyid:ply.plyid, color:ply.color1, name:ply.name}
               corners.push(cornerobj1)
               if(corner2!=null){
                const cornerobj2={corner:corner2, plyid:ply.plyid, color:ply.color2, name:ply.name}
                corners.push(cornerobj2)
               }
               

            })

            corners.sort((corner1, corner2)=>{
                return(corner1.corner-corner2.corner)
            })

            return(corners)
        }
        return(null)
    }




    
    useEffect(()=>{

        if(replay?.eventlog){
            const pieces=[]
                
            const selectedindex=replay.eventlog.findIndex((event)=>event===selectedEvent)
           
            if(selectedindex!==-1){
                replay.eventlog.slice(0,selectedindex+1)?.forEach((event)=>{
                    if(event.type==="placepiece")pieces.push(event)
    
                })
               
            }


            setBoardState(prev=>{ return({corners:replay.corners,placedPieces:pieces})})


        }else{
            if(replay?.corners!=null){
                setBoardState(prev=>{return({...prev, corners:replay.corners})})

            }
        }

        


    }, [selectedEvent,replay])


    return(<div className={"ReplayContainer"+(isLoading?" loading":"")}>

        <div className="ReplayLoading">
            <label>Loading replay...</label>
            <div className="loaderdiv">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

        </div>

        <div className="GameReplayContainer">
        <Game selectedEvent={selectedEvent} replay={boardState}/>
        <ReplayDock selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} replay={replay}/>
        </div>


    </div>)
} 