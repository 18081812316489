import React, {useEffect} from "react"
const defaultmatrix = [ [1, null, 1] ,[1, 1, 1],]
export default function HelmetPiece(props){

    useEffect(()=>{
        props.setDefaultMatrix(defaultmatrix)
        props.setName("U")
    }, [])



    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export {defaultmatrix}